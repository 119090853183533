export default {
    getId: (item) => {
        return item["id"]
    },
    getUserName: (item) => {
        return item["user_name"]
    },
    getText: (item) => {
        return item["text"]
    },
    getCreated: (item) => {
        return item["created"]
    },
    getAvatarHost: (item) => {
        return item["avatar_host"]
    },
    getAvatarName: (item) => {
        return item["avatar_name"]
    },
    getParentId: (item) => {
        return item["parent_id"]
    },
    getParentUserName: (item) => {
        return item["parent_user_name"]
    },
    getParentText: (item) => {
        return item["parent_text"]
    },
    getParentAvatarHost: (item) => {
        return item["parent_avatar_host"]
    },
    getParentAvatarName: (item) => {
        return item["parent_avatar_name"]
    },
    getImages: (item) => {
        return item["images"]
    },
    getModeratorAvatarHost: (item) => {
        return item["moderator_avatar_host"]
    },
    getModeratorAvatarName: (item) => {
        return item["moderator_avatar_name"]
    },
    getModeratorUserName: (item) => {
        return item["moderator_user_name"]
    },
    getIsModerate: (item) => {
        return item["is_moderate"]
    },
    getReject: (item) => {
        return item["reject"]
    },
    getPublisherLink: (item) => {
        return item["publisher_link"]
    },
}
