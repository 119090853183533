import {axios as http} from "@/plugins/http"
import Helper from "@/services/Helper"

function getFormData(obj) {
    const formData = new FormData()

    Object.entries(obj).forEach(([key, val]) => {
        formData.append(key, val)
    })

    return formData
}

export default class Api {
    static comments(payload, limit) {
        let query = {}

        if (payload.object_id !== null) {
            query = Helper.mergeObject(query, {
                "object_id": payload.object_id,
            })
        }

        if (payload.page !== null) {
            query = Helper.mergeObject(query, {
                "offset": (payload.page - 1) * limit,
            })
        }

        if (payload.object_type_id !== null) {
            query = Helper.mergeObject(query, {
                "object_type_id": payload.object_type_id,
            })
        }

        if (payload.category !== null) {
            if (Helper.inArray([payload.category], ["new"])) {
                query = Helper.mergeObject(query, {
                    "is_moderate": "false",
                    "is_reject": "false",
                    "is_delst": "false",
                })
            }

            if (Helper.inArray([payload.category], ["approved"])) {
                query = Helper.mergeObject(query, {
                    "is_moderate": "true",
                    "is_delst": "false",
                })
            }

            if (Helper.inArray([payload.category], ["rejected"])) {
                query = Helper.mergeObject(query, {
                    "is_reject": "true",
                    "is_delst": "false",
                })
            }

            if (Helper.inArray([payload.category], ["removed"])) {
                query = Helper.mergeObject(query, {
                    "is_delst": "true",
                })
            }
        }

        if (payload.query_string !== "") {
            query = Helper.mergeObject(query, {
                "comment_text": payload.query_string,
            })
        }

        if (payload.channels?.length > 0) {
            query = Helper.mergeObject(query, {
                "channel_ids": payload.channels.join(","),
            })
        }

        if (payload.user_ids?.length > 0) {
            query = Helper.mergeObject(query, {
                "user_ids": payload.user_ids.join(","),
            })
        }

        if (payload.min_created !== "") {
            query = Helper.mergeObject(query, {
                "min_created": payload.min_created,
            })
        }

        if (payload.max_created !== "") {
            query = Helper.mergeObject(query, {
                "max_created": payload.max_created,
            })
        }

        query = Helper.mergeObject(query, {
            "limit": limit,
        })

        return http.post("/api/v1/comment/comments", getFormData(query)).then((resp) => {
            return resp.data["items"]
        })
    }

    static edit(id, payload) {
        let query = {}

        if (payload.comment_text !== null) {
            query = Helper.mergeObject(query, {
                "comment_text": payload.comment_text,
            })
        }

        query = Helper.mergeObject(query, {
            "comment_id": id,
        })

        return http.post("/api/v1/comment/edit", getFormData(query));
    }

    static approve(payload) {
        let query = {}

        if (payload.comment_ids !== null) {
            query = Helper.mergeObject(query, {
                "comment_ids": payload.comment_ids.join(","),
            })
        }

        return http.post("/api/v1/comment/moderate", getFormData(query));
    }

    static reject(payload) {
        let query = {}

        if (payload.comment_ids !== null) {
            query = Helper.mergeObject(query, {
                "comment_ids": payload.comment_ids,
            })
        }

        return http.post("/api/v1/comment/reject", getFormData(query));
    }
}
