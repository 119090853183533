<template>
  <loading v-model:active="isLoading" loader="bars"/>
  <component :is="layout"/>
</template>

<script>
import baseLayout from '@/layouts/Base'
import defaultLayout from '@/layouts/Default'
import loginLayout from '@/layouts/Login'
import {ref, watch, computed} from "vue"
import {useRoute} from "vue-router"
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css'
import store from "@/plugins/store"
import "./assets/css/style.css"

export default {
  name: 'App',
  components: {
    baseLayout,
    loginLayout,
    defaultLayout,
    Loading,
  },
  setup() {
    const route = useRoute()

    const layout = ref('');

    let isLoading = computed(() => {
      return store.getters.get('loadingApp')
    });

    watch(route, (to) => {
      if (to.meta.layout !== undefined) {
        layout.value = to.meta.layout
      } else {
        layout.value = "defaultLayout"
      }
    })

    return {
      layout,
      isLoading,
    }
  }
}
</script>
