export default {
    getUsername: (item) => {
        return item["username"]
    },
    getUserAvatarHost: (item) => {
        return item["user_avatar_host"]
    },
    getUserAvatarName: (item) => {
        return item["user_avatar_name"]
    },
    getStatus: (item) => {
        return item["status"]
    },
    getPublishedAt: (item) => {
        return item["published_at"]
    },
    getRemovedAt: (item) => {
        return item["removed_at"]
    },
    getScheduledPublished: (item) => {
        return item["scheduled_published"]
    },
    getScheduledRemoved: (item) => {
        return item["scheduled_removed"]
    },
    getCreatedAt: (item) => {
        return item["created_at"]
    },
    getLink: (item) => {
        return item["link"]
    },
}
