import Helper from "@/services/Helper"
import linkImage from "@/services/Image"
import PostImage from "@/modules/post/services/PostImage"

export class Post {
    constructor(item) {
        this.id = item['id'];
        this.contentLong = item['content_long'];
        this.contentShort = Helper.getContentShort(this.contentLong);
        this.imagePostHost = item['image']['host'] || null;
        this.imagePostName = item['image']['name'] || null;
        this.imageAuthorHost = item['author']['avatar']['host'] || null;
        this.imageAuthorName = item['author']['avatar']['name'] || null;
        this.authorName = item['author']['title'];
        this.channelId = item["channel_id"];
        this.show = false;
    }

    getPostImage(w = null, h = null) {
        return PostImage.handle(this.imagePostHost, this.imagePostName, w, h)
    }

    getAuthorAvatar(w = null) {
        if (this.imageAuthorName) {
            return linkImage.handle(this.imageAuthorHost, this.imageAuthorName, w)
        }
        return "/assets/static/post/no_image.jpg"
    }
    toggleShow() {
        this.show = !this.show
    }
}
