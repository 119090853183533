import {createApp} from 'vue'
import App from './App'

import router from "@/plugins/router"
import store from "@/plugins/store"
import meta from "@/plugins/meta"
import {VueAxios, axios} from "@/plugins/http"
import i18n from "@/plugins/i18n"
import toaster from '@/plugins/toaster'
import {createPinia} from 'pinia'

import module from "@/plugins/module"

const pinia = createPinia()

const app = createApp(App)

app.use(module)
app.use(i18n)
app.use(store)
app.use(router)
app.use(meta)
app.use(VueAxios, axios)
app.use(toaster, {})
app.use(pinia)

app.mount('#app')
