<template>
  <li class="nav-item" v-if="visible()" @click="onClick" id="menu-posts">
    <span class="nav-link">
      <span class="nav-link-icon d-md-none d-lg-inline-block">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-list" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
         <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
         <path d="M9 6l11 0"></path>
         <path d="M9 12l11 0"></path>
         <path d="M9 18l11 0"></path>
         <path d="M5 6l0 .01"></path>
         <path d="M5 12l0 .01"></path>
         <path d="M5 18l0 .01"></path>
      </svg>
      </span>
      <span class="nav-link-title">
        Посты
      </span>
    </span>
  </li>
</template>

<script>
import {defineComponent, ref, watch} from "vue"
import {useRouter} from "vue-router/dist/vue-router"
import StoreCommon from "@/services/StoreCommon"

export default defineComponent({
  name: "Posts",
  setup() {
    const router = useRouter()
    let channelId = ref(null)
    let routeName = ref(null)

    let pages = {
      isPost: () => {
        return routeName.value === "Posts" || routeName.value === "PostCreateUpdate"
      },

      isPromopushka: () => {
        return routeName.value === "Promopushka" || routeName.value === "PromopushkaCreateUpdate"
      },
    }

    watch(() => StoreCommon.get(), (val) => {
      channelId.value = val.post?.channel_id
      routeName.value = val.post?.route_name
    })

    function visible() {
      return (channelId.value && routeName.value)
          && (pages.isPost() || pages.isPromopushka())
    }

    function onClick() {
      if (pages.isPost()) {
        router.push({
          name: 'Posts',
          params: {
            channel_id: channelId.value,
          },
          query: {},
          force: new Date().getTime(),
        })
      } else if (pages.isPromopushka()) {
        router.push({
          name: 'PromopushkaCouponItems',
          params: {
            channel_id: 13,
          },
        })
      }
    }

    return {
      visible,
      onClick,
    }
  }
})
</script>

<style scoped>
  #menu-posts .nav-link:hover {
    cursor: pointer;
  }
</style>