import {axios as http} from "@/plugins/http"
import Helper from "@/services/Helper"

export default class ApiBase {
    static users() {
        return http.post("/api/v1/post/users");
    }

    static postsChannel(query) {
        return http.post("/api/v1/post/posts-channel", query)
    }

    static createDraft(query) {
        return http.post("/api/v1/post/create-draft", query)
    }

    static saveDraft(query) {
        return http.post("/api/v1/post/save-draft", query)
    }

    static post(query) {
        return http.post("/api/v1/post/post", query)
    }

    static item(query) {
        return http.post("/api/v1/post/item", query)
    }

    static save(query) {
        return http.post("/api/v1/post/save", query)
    }

    static saveDelete(query) {
        return http.post("/api/v1/post/save-delete", query)
    }

    static addImage(query) {
        let url = Helper.getApiUrlPost()
        return http.post(url + "/api/v1/hpanel/post/add-image", query)
    }

    static deleteImage(query) {
        return http.post("/api/v1/post/delete-image", query)
    }

    static addVideo(query) {
        let url = Helper.getApiUrlPost()
        return http.post(url + "/api/v1/hpanel/post/add-video", query)
    }

    static deleteVideo(query) {
        return http.post("/api/v1/post/delete-video", query)
    }

    static delete(query) {
        return http.post("/api/v1/post/delete", query)
    }

    static scheduled(query) {
        return http.post("/api/v1/post/scheduled", query)
    }

    static published(query) {
        return http.post("/api/v1/post/published", query)
    }

    static chainItems(query) {
        return http.post("/api/v1/post/chain/items", query)
    }

    static chainBind(query) {
        return http.post("/api/v1/post/chain/bind", query)
    }

    static chainUnbind(query) {
        return http.post("/api/v1/post/chain/unbind", query)
    }

    static promopushkaChainBind(query) {
        return http.post("/api/v1/promopushka/chain/bind", query)
    }

    static promopushkaChainUnbind(query) {
        return http.post("/api/v1/promopushka/chain/unbind", query)
    }

    static chainBindInPost(query) {
        return http.post("/api/v1/post/chain/bind", query)
    }

    static composites(query) {
        return http.post("/api/v1/post/composites", query)
    }

    static links(query) {
        return http.post("/api/v1/post/links", query)
    }

    static channels(query) {
        return http.post("/api/v1/post/channels", query)
    }

    static seeInBot(query) {
        return http.post("/api/v1/post/see-in-bot", query)
    }

    static deleteChain(query) {
        return http.post("/api/v1/post/delete-chain", query)
    }

    static info(query) {
        return http.post("/api/v1/post/info", query)
    }

    static posts(query) {
        return http.post("/api/v1/post/posts", query)
    }

    static statisticRanking(query) {
        return http.post("/api/v1/post/statistic-ranking", query)
    }

    static cancel(query) {
        return http.post("/api/v1/post/cancel", query)
    }

    static actions(query) {
        return http.post("/api/v1/post/actions", query)
    }

    static republished(query) {
        return http.post("/api/v1/post/republished", query)
    }
}
