<template>
  <div>
    <div class="card" id="editor">
      <div class="card-body">
        <div class="mx-lg-3 mt-2 mx-xl-3 mx-md-3 mx-sm-2 mb-2">
          <div id="editorjs" class="editor"></div>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <span :class="{'is-invalid': v$.content.$invalid && v$.content.$dirty}"></span>
      <div class="invalid-feedback" v-for="error of v$.content.$silentErrors" :key="error.$uid">
        {{ error.$message }}
      </div>
    </div>
  </div>
</template>

<script>
import "./css/style.css"
import {defineComponent, onMounted, onUnmounted, ref, reactive, watch} from "vue"
import {editor} from "@/plugins/editor/editor"
import {useVuelidate} from "@vuelidate/core"
import {minLength} from "@/plugins/vuelidator"
import Undo from 'editorjs-undo'

export default defineComponent({
  name: "Editor",
  props: {
    content: {
      type: Object,
    },
    validations: {
      //type: Object,
    },
  },
  setup(props, {emit}) {
    let editorObject = ref(null)
    let content = ref(props.content)

    let $externalResults = reactive({})

    const fields = reactive({
      content: content.value,
    })

    const rules = {
      content: {
        minLength: minLength(0)
      },
    }

    const v$ = useVuelidate(rules, fields, {$externalResults})

    onMounted(() => {
      initEditor()
    })

    onUnmounted(() => {
      destroyEditor()
    })

    watch(() => props.validations, (val) => {
      validate(() => {
        if (val?.content !== undefined) {
          $externalResults.content = val["content"][0]
        }
      })
    })

    function validate(fn) {
      v$.value.$clearExternalResults()
      return v$.value.$validate().then(() => {
        fn()
      })
    }

    function initEditor() {
        editorObject.value = editor({
          id: 'editorjs',
          data: content.value,
          onReady: () => {
            const undo = new Undo({ editor: editorObject.value })
            if (content.value !== null) {
              undo.initialize(content.value)
            }
          },
          onChange: () => {
            editorObject.value.save().then((savedData) => {
              content.value = savedData
              validate(() => {})
              emit("onChange", savedData)
            })
          },
        })
    }

    function destroyEditor() {
      if (editorObject.value) {
        editorObject.value.destroy()
      }
    }

    return {
      v$,
    }
  }
})
</script>

<style scoped>

</style>
