<template>
  <div class="card mb-3" v-if="complaints.length">
    <div class="card-header align-items-center">
      <div class="col">
        <h3 class="card-title">Жалобы</h3>
      </div>
      <div class="col-auto">
        <button v-if="checkedMess.length" class="btn btn-primary" @click="sendSelect">Ответить выбранным</button>
      </div>
    </div>
    <div class="card-table table-responsive">
      <table class="table table-vcenter table-hover">
        <thead>
          <tr>
            <th>
              <input class="form-check-input me-1 m-0" v-model="checkedAll" type="checkbox">
            </th>
            <th>Автор</th>
            <th>Сообщение</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in complaints" :key="item.id" class="table-danger">
            <td>
              <input class="form-check-input" type="checkbox" :id="item.id" :value="item.id" v-model="checkedMess">
            </td>
            <td>
              <img class="br-4 me-1" :src="getImageChannel(item.channel)" />
              <span class="author-name">
                {{ item.author }}
              </span>
            </td>
            <td>
              <div v-if="item.hide" :class="{ 'hidden-text': isNotShow(item) }">
                <div class="element-hidden" v-if="isNotShow(item)" @click="contentShow(item)"></div>
                <div class="d-flex">
                  <IconPhoto class="color-red flex-shrink-0" v-if="hasIcon(item)" />
                  <div class="">
                    <div class="">
                      {{ isNotShow(item) ? '...' : '' }}
                      {{ item.message }}
                    </div>
                    <div div class=" d-flex" v-if="item.imgs">
                      <Lightbox :images="{ thumb: [getImage(element.host, element.name, 100)], origin: [getImage(element.host, element.name)] }" v-for="element in item.imgs" :key="element.name" />
                    </div>
                    <span class="button-link d-inline-block mt-3" @click="contentHide(item)">
                      Скрыть
                    </span>
                  </div>
                </div>
              </div>
              <div v-else class="">
                {{ item.message }}
              </div>
            </td>
            <td class="text-end">
              <button class="btn btn-primary" @click="sendProduct(item)">Ответить</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <ComplainModals :id-modals="nameComplainsModals" :complaints="complaintsAnswer" @send="sendAnswer" />
</template>

<script setup>
import {onMounted, ref, watch} from 'vue'
import ComplainModals from './components/modals/ComplainModals'
import {getChannels} from "@/modules/post/plugins/title/services/channels"
import ChannelImage from '@/modules/post/services/ChannelImage'
import PostImage from '@/modules/post/services/PostImage'
import Lightbox from '@/components/lightbox/Lightbox'
import IconPhoto from "@/assets/icons/IconPhoto"

const props = defineProps(['postId'])

const complaints = ref([]);
const complaintsAnswer = ref([]);
const nameComplainsModals = 'complains-modals'
const complaintsModal = ref(null);
const checkedAll = ref(false)
const checkedMess = ref([])
const channels = ref(getChannels())

watch(checkedAll, function () {
  if (complaints.value.length) {
    if (checkedAll.value) {
      checkedMess.value = complaints.value.map(item => item.id)
    } else {
      checkedMess.value = [];
    }
  }
})

function getComplaints() {
  // Запрос get по id
  let response = [];
  if (props.postId == 54298) {
    response = [
      {
        id: 0,
        message: 'Нет скидки Нет скидки Нет скидки Нет скидки Нет скидки Нет скидки Нет скидки Нет скидки',
        answer: false,
        channel: 7,
        author: 'Vasia Pupkn',
        imgs: [
          {
            host: 'https://storage-srv1.haot.ru',
            name: 'F6zVJVCj81rChgtF0HpV1OrN49X525nUmL8NeKp6JySDa9cBt3QXZmUPNE95.jpg',
          },
          {
            host: 'https://storage-srv1.haot.ru',
            name: 'F6zVJVCj81rChgtF0HpV1OrN49X525nUmL8NeKp6JySDa9cBt3QXZmUPNE95.jpg',
          },
        ],
      },
      {
        id: 1,
        message: 'Где я не нашел скидки вообще?',
        answer: false,
        channel: 3,
        author: 'Ioirgan Potrov',
      },
    ]
  } else if (props.postId == 54218) {
    response = [
      {
        id: 2,
        message: 'Не работает, никогда и ничего у вас лучше бы и не подписывался!!',
        answer: false,
        channel: 9,
        author: 'Krasavec Vasya',
      },
      {
        id: 3,
        message: 'Кончился товар? не где не на шел!',
        answer: false,
        channel: 3,
        author: 'Super Turubo',
        imgs: [{
          host: 'https://storage-srv1.haot.ru',
          name: '04hbEZTkqpSURaENnWXQlfaqyzgpHHPqgB3kjQC45l6yOVPIIKlb48NqRSDC.jpg',
        }]
      },
      {
        id: 4,
        message: 'Опять не успел',
        answer: false,
        channel: 4,
        author: 'Dominator30000',
      },
    ]
  }
  complaints.value = processingResponse(response);
}

function processingResponse(response) {
  return response.map((item) => {
    let characters = item.message.length;
    if ('imgs' in item || characters >= 33) {
      item.hide = true;
      item.show = false;
    } else {
      item.hide = false;
    }
    return item
  })
}

function sendComplains(items) {
  complaintsAnswer.value = items;
  complaintsModal.value.show();
}

function sendProduct(item) {
  sendComplains([item]);
}

function sendSelect() {
  let answers = complaints.value.filter(item => checkedMess.value.includes(item.id))
  sendComplains(answers)
}

function sendAnswer() {
  complaintsModal.value.hide();
  complaintsAnswer.value = [];
  checkedAll.value = false;
  checkedMess.value = [];
  complaints.value = [];
  setTimeout(() => {
    getComplaints();
  }, 1000)
}

function getImageChannel(channelId) {
  return ChannelImage.handle(channels.value[channelId]["image_host"], channels.value[channelId]["image_name"], 20);
}

function getImage(host, name, w) {
  return PostImage.handle(host, name, w);
}

function contentShow(item) {
  item.show = true;
}

function contentHide(item) {
  item.show = false;
}

function isNotShow(item) {
  return !item.show
}

function hasIcon(item) {
  return isNotShow(item) && item.imgs;
}

onMounted(() => {
  complaintsModal.value = new bootstrap.Modal(document.getElementById(nameComplainsModals))
  getComplaints();
})

</script>

<style scoped>
.d-flex {
  gap: 0.5rem;
}

.br-4 {
  border-radius: 4px;
}

.hidden-text {
  height: 1.4285714286em;
  overflow-y: hidden;
  position: relative;
}

.element-hidden {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

@media (max-width: 575.98px) {
  .author-name {
    display: none;
  }

  .table> :not(caption)>*>* {
    padding: 0.3rem 0.3rem;
  }
}
</style>
