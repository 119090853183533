import { defineStore } from 'pinia'

export const useErrStore = defineStore('err', {
    state: () => ({
        err: new Map(),
    }),
    getters: {},
    actions: {
        add(key, val) {
            if (!this.err.has(val)) {
                this.err.set(key, val)
            }
        },
        rm(key) {
            this.err.delete(key)
        },
        getAll() {
            return this.err
        }
    }
})
