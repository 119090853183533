<template>
  <div class="filter-shops" id="filter-shops" v-if="isEnable()">
    <div class="filter-shops__search">
      <label class="form-label">Название или описание</label>
      <div class="row g-2">
        <div class="col">
          <div class="input-icon">
            <input @keyup.enter="events.onSearch" type="text" v-model="data.search" class="form-control" placeholder="Название или описание магазина">
            <button class="btn input-icon-addon__button" @click="events.onSearch">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="10" cy="10" r="7" />
                <line x1="21" y1="21" x2="15" y2="15" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="filter-shops__select category">
      <label class="form-label">Категория</label>
      <Multiselect @change="events.onCategory" v-model="data.categories" mode="tags" placeholder="Не выбрано" noResultsText="Результатов не найдено" :close-on-select="true" :options="variantsData.categories">
        <template v-slot:tag="{ option, handleTagRemove, disabled }">
          <div class="multiselect-tag element" :class="{ 'is-disabled': disabled }">
            <div class="category-name">{{ option.name }}</div>
            <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
              <span class="multiselect-tag-remove-icon"></span>
            </span>
          </div>
        </template>
        <template v-slot:option="{ option }">
          <div class="option-category">
            <div class="category-name"> {{ option.name }}</div>
          </div>
        </template>
      </Multiselect>
    </div>
  </div>
</template>

<script>
import './css/style.css'
import {defineComponent, onMounted, reactive} from "vue"
import ApiBase from "@/modules/promopushka/services/ApiBase"
import Multiselect from "@/plugins/multiselect"

export default defineComponent({
  name: "Filter",
  components: {
    Multiselect,
  },
  props: {
    params: {
      type: Object,
      required: true
    },
  },
  setup(props, {emit}) {
    const variantsData = reactive({
      categories: [],
    })

    let data = reactive({
      search: props.params.search ?? '',
      categories: props.params.categories ?? [],
      enable: props.params.categories ?? true,
    })

    const events = {
      onCategory: (value) => {
        // тут приходится подменять вручную, а не использовать v-model
        // так как v-model реагирует перед событием и не учитывает текущий выбор
        // и наполняется предыдущим набором данных
        data.categories = value
        onFilter()
      },
      onSearch: () => {
        onFilter()
      },
      onStatuses: () => {
        onFilter()
      }
    }

    function categoriesMultiSelect(items) {
      for (let prop in items) {
        let obj = items[prop]

        variantsData.categories.push({
          value: obj["id"],
          name: obj["title"],
        })
      }
    }

    function categories() {
      return ApiBase.shopCategories().then((resp) => {
        let items = resp.data.items
        categoriesMultiSelect(items)
      })
    }

    function onFilter() {
      emit("onFilter", getResult())
    }

    function getResult() {
      return {
        search: data.search,
        categories: data.categories,
      }
    }

    function isEnable() {
      return props.params.enable === true
    }

    onMounted(() => {
      categories()
      onFilter()
    })

    return {
      events,
      data,
      variantsData,
      isEnable,
    }
  }
})
</script>
