<template>
    <div class="row">
        <div class="col-md-8">
            <div class="card" id="post-ref-posts">
                <div class="card-header">
                    <h3 class="card-title">Список купонов</h3>
            
                    <div class="card-actions">
                        <button class="btn btn-danger" :onClick="handleDeleteConfirm">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash me-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <line x1="4" y1="7" x2="20" y2="7"></line>
                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                <line x1="14" y1="11" x2="14" y2="17"></line>
                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                            </svg>
                            Удалить все
                        </button>
                    </div>
                </div>
            
                <div class="table-responsive">
                    <table class="table card-table">
                    <thead>
                        <tr>
                            <th>Купон</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in getCoupons().items" :key="item.coupon">
                            <td>{{ item.coupon }}</td>
                        </tr>
                    </tbody>
                    </table>
                    <div class="text-center p-1" v-if="isLoading()">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>

                <div class="card-footer">
                    Всего: <b>{{ getCoupons().total }}</b>; 
                    Отдано: <b>{{ getCoupons().used }}</b>; 
                    Осталось: <b>{{ getCoupons().left }}</b>; 
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <CouponListUpload :coupon="getCoupon()" @onChange="handleInit" />
        </div>
    </div>
</template>

<script>

import {defineComponent, ref, watch, onMounted, reactive} from "vue"
import CouponListUpload from "./CouponListUpload"
import Api from "@/modules/promopushka/pages/edit/services/Api"
import Notify from "@/services/Notify"

export default defineComponent({
    name: "CouponList",
    components: {
        CouponListUpload
    },
    props: {
        coupon: {
            type: Number,
            required: true
        },
    },
    setup(props) {
        let couponId = ref(0)
        let coupons = reactive({
            items: [],
            total: 0,
            used: 0,
            left: 0
        })
        let loading = ref(true)

        onMounted(() => {
            couponId.value = props.coupon
            handleInit()
        })

        watch(() => props.coupon, (val) => {
            couponId.value = val
        })

        function getCoupon() {
            return couponId.value
        }

        function handleInit() {
            loading.value = true
            Api.statCouponList({id: couponId.value}).then((data) => {
                coupons.items = data.items
                coupons.total = data.coupon_total
                coupons.used = data.coupon_used
                coupons.left = data.coupon_left
                loading.value = false
            })
        }

        function handleDeleteConfirm() {
            if (confirm("Вы уверены что хотите удалить все купоны?") === true) {
                handleDelete()
            }
        }

        function handleDelete() {
          Api.deleteCouponList({id: couponId.value}).then(() => {
            Notify.success('Все купоны удалены');
            handleInit()
          }).catch((err) => {
            Notify.error('Не удалось удалить купоны', err);
          })
        }

        function getCoupons() {
            return coupons
        }

        function isLoading() {
            return loading.value
        }

        return {
            getCoupon,
            handleInit,
            getCoupons,
            isLoading,
            handleDeleteConfirm
        }
    },
})
</script>

<style scoped></style>
  