<template>
  <div class="filter-post" id="filter-post" v-if="isEnable() && isReadyComponent()">
    <div class="filter-post__search">
      <label class="form-label">Поисковый запрос</label>
      <div class="row g-2">
        <div class="col">
          <div class="input-icon">
            <input @keyup.enter="events.onSearch" type="text" v-model="bootData.search" class="form-control" placeholder="Напишите что-нибудь">
            <!-- <button @click="events.onSearch" class="input-icon-addon__button btn btn-primary">Go</button> -->
            <button class="btn input-icon-addon__button" @click="events.onSearch">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="10" cy="10" r="7" />
                <line x1="21" y1="21" x2="15" y2="15" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="filter-post__date">
      <label class="form-label">Дата</label>
      <Datepicker auto-apply v-model="bootData.date" @update:modelValue="events.applyDate" range multi-calendars locale="ru" format="dd.MM.yyyy" placeholder="Выбрать дату" :enable-time-picker="false" />
    </div>

    <div class="filter-post__select users">
      <label class="form-label">Пользователь</label>
      <Multiselect @change="events.onUser" v-model="bootData.users" mode="tags" placeholder="Не выбрано" noResultsText="Результатов не найдено" :close-on-select="true" :options="variantsData.users" :searchable="true" :trackBy="'name'">
        <template v-slot:tag="{ option, handleTagRemove, disabled }">
          <div class="multiselect-tag element" :class="{ 'is-disabled': disabled }">
            <img :src="option.image">
            <div class="user-name">{{ option.name }}</div>
            <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
              <span class="multiselect-tag-remove-icon"></span>
            </span>
          </div>
        </template>
        <template v-slot:option="{ option }">
          <div class="option-user">
            <div class="user-image-wrap">
              <img class="user-image" :src="option.image">
            </div>
            <div class="user-name"> {{ option.name }}</div>
          </div>
        </template>
      </Multiselect>
    </div>

    <div class="filter-post__select types-commerce">
      <label class="form-label">Тип</label>
      <Multiselect @change="events.onTypesCommerce" v-model="bootData.typesCommerce" mode="tags" placeholder="Нет" noResultsText="Результатов не найдено" :close-on-select="true" :options="variantsData.typesCommerce">
        <template v-slot:tag="{ option, handleTagRemove, disabled }">
          <div class="multiselect-tag element" :class="{ 'is-disabled': disabled }">
            <div class="user-name">{{ option.name }}</div>
            <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
              <span class="multiselect-tag-remove-icon"></span>
            </span>
          </div>
        </template>
        <template v-slot:option="{ option }">
          <div class="option-user">
            <div class="user-name"> {{ option.name }}</div>
          </div>
        </template>
      </Multiselect>
    </div>

    <div class="filter-post__buttons">
      <label class="form-label">Статус</label>
      <div class="form-selectgroup">
        <label class="form-selectgroup-item">
          <input @change="events.onStatuses" type="checkbox" v-model="bootData.statuses.published" class="form-selectgroup-input">
          <span class="form-selectgroup-label">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-sun me-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <circle cx="12" cy="12" r="4"></circle>
              <path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7"></path>
            </svg>
            Активно
          </span>
        </label>
        <label class="form-selectgroup-item">
          <input @change="events.onStatuses" type="checkbox" v-model="bootData.statuses.scheduled" class="form-selectgroup-input">
          <span class="form-selectgroup-label">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-calendar-event me-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <rect x="4" y="5" width="16" height="16" rx="2"></rect>
              <line x1="16" y1="3" x2="16" y2="7"></line>
              <line x1="8" y1="3" x2="8" y2="7"></line>
              <line x1="4" y1="11" x2="20" y2="11"></line>
              <rect x="8" y="15" width="2" height="2"></rect>
            </svg>
            Отложено
          </span>
        </label>
        <label class="form-selectgroup-item">
          <input @change="events.onStatuses" type="checkbox" v-model="bootData.statuses.removed" class="form-selectgroup-input">
          <span class="form-selectgroup-label">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash me-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <line x1="4" y1="7" x2="20" y2="7"></line>
              <line x1="10" y1="11" x2="10" y2="17"></line>
              <line x1="14" y1="11" x2="14" y2="17"></line>
              <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
              <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
            </svg>
            Удалено
          </span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import "./css/style.css"
import {defineComponent, onMounted, reactive, watch, toRaw, ref} from "vue"
import Multiselect from "@/plugins/multiselect"
import Datepicker from "@/plugins/datepicker"
import Api from "@/modules/post/components/filter/services/Api"
import Image from "@/services/Image"
import DT from "@/services/DT"

export default defineComponent({
  name: "Filter",
  components: {
    Datepicker,
    Multiselect,
  },
  props: {
    enable: {
      type: Boolean,
      required: true
    },
    params: {
      type: Object,
      required: true
    },
  },
  setup(props, {emit}) {
    const resetBootData = (val) => {
      bootData.search = val.search ?? '';
      bootData.date = val.date ?? null;
      bootData.users = val.users ?? [];
      bootData.typesCommerce = val.typesCommerce ?? [];
      bootData.statuses = {
        published: val.statuses?.published ?? true,
        scheduled: val.statuses?.scheduled ?? true,
        removed: val.statuses?.removed ?? false,
        draft: val.statuses?.draft ?? false,
      };
    };

    let readyComponent = ref(false)

    let bootData = reactive({})

    const variantsData = reactive({
      users: [],
      typesCommerce: [],
    })

    const events = {
      applyDate: () => {
        onFilter()
      },
      onUser: (value) => {
        // тут приходится подменять вручную, а не использовать v-model
        // так как v-model реагирует перед событием и не учитывает текущий выбор
        // и наполняется предыдущим набором данных
        bootData.users = value
        onFilter()
      },
      onTypesCommerce: (value) => {
        // тут приходится подменять вручную, а не использовать v-model
        // так как v-model реагирует перед событием и не учитывает текущий выбор
        // и наполняется предыдущим набором данных
        bootData.typesCommerce = value
        onFilter()
      },
      onSearch: () => {
        onFilter()
      },
      onStatuses: () => {
        onFilter()
      }
    }

    watch(() => props.params, (val) => {
      resetBootData(toRaw(val))
      onFilter()
    })

    onMounted(() => {
      resetBootData(props.params)
      users()
      typesCommerce()
      onFilter(true)
      setReadyComponent(true)
    })

    function setReadyComponent(val) {
      readyComponent.value = val
    }

    function isReadyComponent() {
      return readyComponent.value === true
    }

    function users() {
      return Api.users().then((resp) => {
        usersMultiSelect(resp)
      })
    }

    function typesCommerce() {
      let variants = [];

      for (let i = 1; i <= 8; i++) {
        variants.push(
          {
            "id": i,
            "name": "Тип-" + i,
          }
        )
      }

      variants.push(
          {
            "id": 9,
            "name": "Без оплаты",
          }
      )

      typesCommerceMultiSelect(variants)
    }

    function onFilter(auto) {
      auto = auto === true

      if (auto === false) {
        const url = new URL(window.location.href);
        url.searchParams.set("filter", "1");
        window.history.pushState({}, '', url);
      }

      pushParent(auto)
    }

    function usersMultiSelect(items) {
      for (let prop in items) {
        let obj = items[prop]

        variantsData.users.push({
          value: obj["id"],
          name: obj["name"],
          image: Image.handle(obj["avatar_host"], obj["avatar_name"], 60)
        })
      }
    }

    function typesCommerceMultiSelect(items) {
      for (let prop in items) {
        let obj = items[prop]

        variantsData.typesCommerce.push({
          value: obj["id"],
          name: obj["name"],
        })
      }
    }

    function pushParent(auto) {
      emit("onFilter", getResult(auto))
    }

    function getResult(auto) {
      let date = forDateResult(bootData.date)

      return {
        auto: auto,
        search: bootData.search,
        date: {
          from: date[0],
          to: date[1],
        },
        users: bootData.users,
        types_commerce: bootData.typesCommerce,
        statuses: {
          published: bootData.statuses.published,
          scheduled: bootData.statuses.scheduled,
          removed: bootData.statuses.removed,
          draft: bootData.statuses.draft,
        },
      }
    }

    function forDateResult(date) {
      if (date === null) {
        return [
          null,
          null,
        ]
      }

      let from = new DT(date[0]).startTimeOfDay()
      let to = new DT(date[1]).endTimeOfDay()

      from.setMonth(from.getMonthNatural())
      to.setMonth(to.getMonthNatural())

      return [
        from.get("%Y-%M-%D %H:%I:%S"),
        to.get("%Y-%M-%D %H:%I:%S"),
      ]
    }

    function isEnable() {
      return props.enable === true
    }

    return {
      events,
      bootData,
      variantsData,
      isEnable,
      isReadyComponent,
    }
  }
})
</script>

<style scoped></style>
