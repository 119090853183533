<template>
  <router-view></router-view>
  BASE
</template>

<script>
export default {
  name: "Base",
}
</script>
