<template>
  <div class="statistic" v-if="isView()">
    <div class="statistic-item badge bg-gray" data-bs-toggle="modal" :data-bs-target="'#' + modalId + postId">
      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-medal-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M9 3h6l3 7l-6 2l-6 -2z" />
        <path d="M12 12l-3 -9" />
        <path d="M15 11l-3 -8" />
        <path d="M12 19.5l-3 1.5l.5 -3.5l-2 -2l3 -.5l1.5 -3l1.5 3l3 .5l-2 2l.5 3.5z" />
      </svg>
      <span class="count">{{ point }}</span>
    </div>
    <Teleport to="body">
      <ModalInfo :modal-id="modalId + postId" title="Рейтинг">
        <p v-if="comment.length" v-html="comment"></p>
        <p v-else>Данных пока нет.</p>
      </ModalInfo>
    </Teleport>
  </div>
</template>

<script>
import {defineComponent, watch, ref, toRaw} from "vue"
import StoreRegister from "@/plugins/store/services/StoreRegister"
import stores from "./stores"
import {
  getData as statisticGetData, storeName as statisticStoreName
} from "./services/statistic"
import ModalInfo from '@/components/modals/modal_info/ModalInfo'

StoreRegister.handle(statisticStoreName, stores)

export default defineComponent({
  name: "StatisticPoint",
  components: {
    ModalInfo,
  },
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  setup(props) {
    let point = ref(0);
    let comment = ref('');
    const modalId = 'infoPoint'
    const postId = props.data["post_id"];

    watch(() => statisticGetData(), (val) => {
      render(toRaw(val))
    })

    function render(payload) {
      if (payload[props.data["post_id"]] !== undefined) {
        point.value = payload[props.data["post_id"]]["val"]
        comment.value = payload[props.data["post_id"]]["comment"]
      }
    }

    function isView() {
      if (point.value === 0) {
        return false
      }

      return true
    }

    return {
      point,
      comment,
      postId,
      modalId,
      isView,
    }
  }
})
</script>

<style scoped src="./css/style.css"></style>