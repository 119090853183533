import StoreRegister from "@/plugins/store/services/StoreRegister"
import stores from "@/modules/role/stores"

export default new class Install {
    handle() {
        StoreRegister.handle("role", stores)
        //this.setPermissions()
    }

    /*setPermissions() {
        watch(() => AuthStore.isAuth(), (isAuth) => {
            if (isAuth) {
                Store.setPermissions().then()
            }
        })
    }*/
}