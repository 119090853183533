import store from "@/plugins/store"

const storeName = "channels.comment_count"

function fill(ids, options) {
    if (ids.length === 0) {
        return false
    }

    setData({
        ids,
        options,
    }).then(() => {})
}

function setData(ids) {
    return store.dispatch(storeName + "/setData", ids)
}

function getData() {
    return store.getters[storeName + "/getData"]()
}

export {fill, getData, storeName}
