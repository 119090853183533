import DTFormat from "@/services/DTFormat"
import DT from "@/services/DT"

export default class DTBackend extends DT {
    constructor(date) {
        let dt = new DTFormat(date)
        // .correctTZ() тут можно применить, тогда сработает по всему проекту,
        // но для этого нужно чтобы все сервисы возвращали по UTC
        super(dt.getObject())
    }
}
