import Storage from "@/services/Storage"
import {v4 as uuid} from "uuid"

export default new class Client extends Storage {
    constructor() {
        super("auth_client");
    }

    getClientId() {
        return new Promise((resolve) => {
            let clientId = this.genClientId()

            resolve(clientId)
        });
    }
    genClientId() {
        let clientId = this.get();

        if (clientId === null) {
            clientId = uuid();
            clientId = clientId.replace(/-/g, "");
        }

        this.set(clientId)

        return clientId
    }
}
