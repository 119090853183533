import * as validators from "@vuelidate/validators"
import i18n from "@/plugins/i18n"

const { createI18nMessage, helpers } = validators;

const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) });

export const required = withI18nMessage(validators.required, {
  withArguments: true,
});
export const between = withI18nMessage(validators.between, {
  withArguments: true,
});
export const minLength = withI18nMessage(validators.minLength, {
  withArguments: true,
});
export const maxValue = withI18nMessage(validators.maxValue, {
  withArguments: true,
});
export const url = withI18nMessage(validators.url, { withArguments: true });

// validators that expect a parameter should have `{ withArguments: true }` passed as a second parameter, to annotate they should be wrapped
/*export const minLength = withI18nMessage(validators.minLength, { withArguments: true })
// or you can provide the param at definition, statically
export const maxLength = withI18nMessage(validators.maxLength(10))*/

const _fileSize = (options = {}) => {
  return helpers.withParams(options, value => {
    if (!value) {
      return true
    }
    const fileSizeinKb = value.size / 1024
    const size = Math.round(fileSizeinKb * 100) / 100 // convert up to 2 decimal place
    return size <= options.maxFileSizeInB / 1024
  })
}

export const fileSize = withI18nMessage(_fileSize, { withArguments: true });
