import { useErrStore } from './ErrStorage'

export default {
    add(key, val) {
        let err = useErrStore()
        err.add(key, val)
    },
    rm(key) {
        let err = useErrStore()
        err.rm(key)
    },
    getAll() {
        let err = useErrStore()
        return err.getAll()
    }
}
