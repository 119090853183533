import Api from "./Api"

function createDraftInit(q) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            createDraftApi(q).then((ID) => {
                resolve(ID)
            }).catch(() => {
                reject()
            })
        }, 1000)
    })
}

function createDraftApi(q) {
    return Api.createDraft(q)
}

export default createDraftInit
