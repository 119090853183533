<template>
  <metainfo></metainfo>

  <div class="page-header d-print-none">
    <div class="container-xl">
      <div class="d-flex g-2 align-items-center">
        <div class="flex-grow-1 me-1">
          <h2 class="page-title">
            Жалобы
          </h2>
          <div class="edit-secondary mt-1">Страница на разработке</div>
        </div>
        <div class="">
          Что-нить
        </div>
      </div>
    </div>
  </div>

  <div class="page-body" id="complaints">
    <div class="container-xl ">

      <div class="card">
        <div v-if="page.status == 'loaded'" class="table-responsive">
          <table class="table table-vcenter card-table table-striped">
            <thead>
              <tr>
                <th v-if="!isMobile()">Картинка</th>
                <th>Текст</th>
                <th v-if="!isMobile()">Автор</th>
                <th>Жалоб</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in posts" :key="item.id">
                <td class="img-card" v-if="!isMobile()">
                  <Lightbox :images="{ thumb: [item.getPostImage(500)], origin: [item.getPostImage()] }" />
                  <!-- <img :src="item.image"> -->
                </td>

                <td class="text-secondary td-text" :class="{ 'cover': !item.show }" @click="item.toggleShow()">
                  <Lightbox v-if="page.width < page.widthMobile && item.show" :images="{ thumb: [item.getPostImage(500)], origin: [item.getPostImage()] }" />

                  <div v-if="item.show" :class="{ 'mt-3': item.show }" v-html="item.contentLong"> </div>
                  <div v-else class="content-short d-flex" v-html="item.contentShort"></div>

                  <div class="d-flex align-items-center mt-3" v-if="page.width < page.widthMobile && item.show">
                    <span class="avatar avatar-sm me-3" :style="{ backgroundImage: 'url(' + item.getAuthorAvatar() + ')' }"></span>
                    <div class="author">
                      <div class="author__name">{{ item.authorName }}</div>
                      <div class="channel">
                        <Channel :data="{
                          channel_id: item.channelId
                        }
                          " />
                      </div>
                    </div>
                  </div>
                </td>

                <td class="text-secondary text-nowrap" v-if="!isMobile()">
                  <div class="d-flex align-items-center">
                    <span class="avatar avatar-sm me-3" :style="{ backgroundImage: 'url(' + item.getAuthorAvatar() + ')' }"></span>
                    <div class="author">
                      <div class="author__name">{{ item.authorName }}</div>
                      <div class="channel">
                        <Channel :data="{
                          channel_id: item.channelId
                        }
                          " />
                      </div>
                    </div>
                  </div>
                </td>

                <td class="text-secondary">
                  <div class="d-flex justify-content-center">
                    <span class="badge badge-pill bg-red">6</span>
                  </div>
                </td>

              </tr>
            </tbody>
          </table>
        </div>

        <div class="card-footer d-flex align-items-center">
          <p class="m-0 text-secondary">Показано с <span>1</span> по <span>8</span> из <span>16</span> записей</p>
          <ul class="pagination m-0 ms-auto">
            <li class="page-item disabled">
              <a class="page-link" href="#" tabindex="-1" aria-disabled="true">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M15 6l-6 6l6 6"></path>
                </svg>
                пред.
              </a>
            </li>
            <li class="page-item"><a class="page-link" href="#">1</a></li>
            <li class="page-item active"><a class="page-link" href="#">2</a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li>
            <li class="page-item"><a class="page-link" href="#">4</a></li>
            <li class="page-item"><a class="page-link" href="#">5</a></li>
            <li class="page-item">
              <a class="page-link" href="#">
                след.
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M9 6l6 6l-6 6"></path>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, onUnmounted, ref} from "vue"
import {axios as http} from "@/plugins/http"
import Helper from "@/services/Helper"
import Lightbox from "@/components/lightbox/Lightbox"
import {Post} from "@/services/Post"
import Channel from "@/modules/post/components/list/components/channel/Channel"

export default defineComponent({
  name: "Complaints",
  components: {
    Channel,
    Lightbox,
  },
  setup() {
    let query = {
      "search": "",
      "date": {
        "from": null,
        "to": null
      },
      "users": [],
      "statuses": {
        "published": true,
        "scheduled": true,
        "removed": false,
        "draft": false
      },
      "limit": 18,
      "offset": 0,
      "page": "search",
    }

    let page = ref({
      status: 'idl',
      width: document.documentElement.clientWidth,
      widthMobile: 576,
    })

    function isMobile() {
      return page.value.width < page.value.widthMobile
    }

    class ApiBase {
      static search(query) {
        return http.post("/api/hpanel/post/search", query)
      }
    }

    class ConvertQuery {
      static handle(query) {
        let q = {}
        let statuses = []

        if (query["date"]["from"] !== null) {
          q = Helper.mergeObject(q, {
            "from_date": query["date"]["from"],
          })
        }

        if (query["date"]["to"] !== null) {
          q = Helper.mergeObject(q, {
            "to_date": query["date"]["to"],
          })
        }

        if (query["users"].length > 0) {
          q = Helper.mergeObject(q, {
            "user_ids": query["users"],
          })
        }

        if (query["search"] !== "") {
          q = Helper.mergeObject(q, {
            "query_string": query["search"],
          })
        }

        if (query.statuses?.published) {
          statuses.push("published")
        }

        if (query.statuses?.removed) {
          statuses.push("removed")
        }

        if (query.statuses?.scheduled) {
          statuses.push("scheduled")
        }

        if (query.statuses?.draft) {
          statuses.push("draft")
        }

        if (statuses.join(",")) {
          q = Helper.mergeObject(q, {
            "statuses": statuses.join(","),
          })
        }

        return Helper.mergeObject(q, {
          "limit": query["limit"],
          "offset": query["offset"],
          "channel_id": query["channel_id"],
        })
      }
    }

    class Api {
      static search(query) {
        let q = ConvertQuery.handle(query)
        return ApiBase.search(q)
      }
    }

    function convertToPost(posts) {
      return posts.map((item) => {
        return new Post(item)
      })
    }

    let posts = ref('');
    function getData() {
      page.value.status = 'loading'
      Api.search(query).then((resp) => {
        page.value.status = 'loaded'
        posts.value = convertToPost(resp.data.items);
      })
    }

    getData();

    function editWidth() {
      page.value.width = document.documentElement.clientWidth;
    }

    onMounted(() => {
      window.addEventListener('resize', editWidth)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', editWidth)
    })

    return {
      page,
      posts,
      isMobile,
    }
  },
})
</script>

<style scoped src="./css/style.css"></style>
<style>
#complaints p:last-child {
  margin-bottom: 0;
}

#complaints .content-short>p {
  text-wrap: nowrap;
  position: absolute;

}
</style>