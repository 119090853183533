<template>
  <template v-if="isLoadData()">
    <div class="text-center p-1">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </template>
  <template v-if="isShowComponent()">
    <div class="card mb-2" id="transactions">
      <div class="card-header justify-content-between">
        <h3 class="card-title me-3">Транзакции</h3>
        <div class="d-flex flex-wrap">
          <div class="p-1">
            <button class="btn" :class="data['element']['type'] === 'post' ? 'btn-green' : 'btn-yellow'" @click="onClickSellChart()">
              <span class="spinner-border spinner-border-sm me-2" role="status" v-if="isLoadClickSellChart()"></span>
              Графики
            </button>
          </div>
          <div class="p-1">
            <button class="btn" :class="!isShowGroup() ? 'btn-primary' : 'btn-warning'" @click="groupItems('price')"> {{ !isShowGroup() ? "Группировать" : "Отмена" }}</button>
          </div>
        </div>
      </div>
      <div class="table-responsive">

        <template v-if="isViewClickSellChart()">
          <ClickSellChart
              :data="{
                type: data['element']['type'],
                post_id: data['post_id'],
              }"
              @onAction="onActionClickSell"
          />
        </template>

        <table v-if="!isShowGroup()" class="table table-vcenter card-table table-striped">
          <thead>
          <tr>
            <th width="15%">Дата</th>
            <th width="15%">Цена</th>
            <th width="15%">Ссылка</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) of getItems()" :key="index">
            <td v-html="row.getCreatedAtTransact(item)" class="datetime"></td>
            <td>{{ row.getPrice(item) }}</td>
            <td>
              <a target="_blank" :href="row.getLink(item)">{{ row.getHashSell(item) }}</a>
            </td>
          </tr>
          </tbody>
        </table>

        <table v-if="isShowGroup()" class="table table-vcenter">
          <thead>
            <tr>
              <th class="text-end" width="15%">Цена</th>
              <th width="15%">Количество</th>
            </tr>
          </thead>
          <tbody v-for="value of getItemsGroup()" :key="itemGroup.getProperty(value)">
            <tr class="pointer" :class="{ 'row-show': itemGroup.getShow(value) }" @click="setShow(value)">
              <td class="text-end">{{ formatPrice(itemGroup.getProperty(value)) }}</td>
              <td>{{ itemGroup.getCount(value) }}</td>
            </tr>
            <tr class="table-internal" v-if="value.show">
              <td colspan="2">
                <table class="table table-vcenter card-table  card-table table-striped">
                  <thead>
                    <tr>
                      <th class="text-end" width="15%">Дата</th>
                      <th width="15%">Ссылка</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) of itemGroup.getTransactions(value)" :key="index">
                      <td v-html="row.getCreatedAtTransact(item)" class="datetime text-end"></td>
                      <td>
                        <a target="_blank" :href="row.getLink(item)">{{ row.getHashSell(item) }}</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
</template>

<script>
import "./css/style.css"
import {defineComponent, onMounted, ref, watch} from "vue"
import List from "@/modules/post/components/transactions/services/Api"
import element from "@/modules/post/components/transactions/services/element"
import Helper from "@/services/Helper"
import DTBackend from "@/services/DTBackend"
import ClickSellChart from "./services/components/click_sell_chart/ClickSellChart"
import cfg from "@/services/Config"

export default defineComponent({
  name: "Transactions",
  components: {
    ClickSellChart
  },
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  setup(props) {
    let onlySelfPost = ref(false)
    let loadData = ref(null)
    let items = ref([])
    let itemsGroup = ref({show: false, items: []})
    let viewClickSellChart = ref(false)
    let loadClickSellChart = ref(false)

    let row = {
      getCreatedAtTransact: (item) => {
        let result
        let dt
        let createdAtTransact = element.getCreatedAtTransact(item)

        dt = new DTBackend(createdAtTransact)
        result = dt.get("%D.%M.%y <span>%H:%I</span>")

        return result
      },

      getLink: (item) => {
        let hashSell = element.getHashSell(item)

        return "https://hlv.su/" + hashSell
      },

      getHashSell: (item) => {
        return element.getHashSell(item)
      },

      getPrice: (item) => {
        return Helper.formatMoney(element.getPrice(item), 2, ".", " ")
      },
    }

    const itemGroup = {
      getProperty: (items) => {
        return items.property
      },
      getCount: (items) => {
        return items.count
      },
      getTransactions: (items) => {
        return items.transactions
      },
      getShow: (items) => {
        return items.show
      },
    }

    onMounted(() => {
      if (props.data["element"]["show"] === true) {
        init()
      }
    })

    watch(() => [props.data["element"]["show"], props.data["element"]["type"]], ([show]) => {
      viewClickSellChart.value = false
      
      if (show === true) {
        init();
      } else {
        clearData()
      }
    })

    function init() {
      loadDataStart()

      let promise = {}
      if (props.data["element"]["type"] === "post") {
        promise = List.statisticPostOrders(props.data["post_id"], getStatisticTypeId())
      } else if (props.data["element"]["type"] === "chain") {
        promise = List.statisticPostOrdersChain(props.data["post_id"], getStatisticTypeId())
      } else {
        loadDataStop()
        throw new Error("Неопределен тип")
      }

      promise.then((resp) => {
        setItems(resp)
      }).finally(() => {
        loadDataStop()
      })
    }

    function isShowComponent() {
      return hasItems()
    }

    function isLoadData() {
      return loadData.value === true
    }

    function loadDataStart() {
      loadData.value = true
    }

    function loadDataStop() {
      loadData.value = false
    }

    function setItems(val) {
      items.value = val
    }

    function getItems() {
      return items.value
    }

    function hasItems() {
      return !Helper.isEmptyObject(items.value)
    }

    function clearData() {
      setItems([])
      loadData.value = null
    }

    function groupItems(property) {
      if (!isShowGroup()) {
        let groupItems = {}
        itemsGroup.value.items = []
        items.value.forEach(el => {
          if (!(el[property] in groupItems)) {
            groupItems[el[property]] = {count: 1, transactions: [], show: false}
          } else {
            groupItems[el[property]].count++
          }
          groupItems[el[property]].transactions.push(el)
        })
        for (let key in groupItems) {
          itemsGroup.value.items.push({property: key, count: groupItems[key].count, transactions: groupItems[key].transactions, show: false});
        }

        itemsGroup.value.items.sort(sortPriceGroup)

        setShow(itemsGroup.value)
      } else {
        setShow(itemsGroup.value)
      }
    }

    function sortPriceGroup(a, b) {
      return Helper.compareNumeric(itemGroup.getCount(a), itemGroup.getCount(b))
    }

    function setShow(items) {
      items.show = !items.show
    }

    function isShowGroup() {
      return itemsGroup.value.show
    }

    function formatPrice(item) {
      return Helper.formatMoney(item, 2, ".", " ")
    }

    function getItemsGroup() {
      return itemsGroup.value.items
    }

    function isViewClickSellChart() {
      return viewClickSellChart.value
    }

    function onClickSellChart() {
      viewClickSellChart.value = !viewClickSellChart.value
    }

    function onActionClickSell(data) {
      loadClickSellChart.value = data["loading"]
    }

    function isLoadClickSellChart() {
      return loadClickSellChart.value
    }

    function getStatisticTypeId() {
      return cfg._("statistic", "types", "post")
    }

    return {
      getItems,
      element,
      row,
      itemsGroup,
      itemGroup,
      isShowComponent,
      isLoadData,
      groupItems,
      isShowGroup,
      getItemsGroup,
      setShow,
      formatPrice,
      onlySelfPost,
      isViewClickSellChart,
      onClickSellChart,
      onActionClickSell,
      isLoadClickSellChart,
    }
  }
})
</script>

<style scoped></style>
