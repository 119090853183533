import cfg from "@/services/Config"
import Client from "@/modules/auth/services/Client"

class WebSocketClient {
    constructor(url) {
        this.url = url;
        this.socket = null;
        this.messageHandlers = []
        this.connect().then(() => {})
        this.reconnectInterval = null
    }

    async connect() {
        let clientId = await Client.getClientId()

        if (!clientId) {
            //console.error('Ошибка получения client_id')
            return
        }

        const query = `${this.url}?client_id=${clientId}`
        this.socket = new WebSocket(query);

        this.socket.onopen = () => {
            //console.log('WebSocket соединение установлено')

            this.stopReconnect()
        };

        this.socket.onclose = (event) => {
            if (event.wasClean) {
                //console.log('WebSocket соединение закрыто чисто')
            } else {
                //console.error('WebSocket соединение закрыто с ошибкой:', event.reason)
            }

            this.startReconnect()
        };

        this.socket.onerror = () => {
            //(error)
            //console.error('WebSocket ошибка:', error)
        };

        this.socket.onmessage = (event) => {
            this.messageHandlers.forEach(handler => handler(event.data))
        };
    }

    stopReconnect() {
        if (this.reconnectInterval) {
            clearInterval(this.reconnectInterval);
            this.reconnectInterval = null
        }
    }

    startReconnect() {
        if (!this.reconnectInterval) {
            this.reconnectInterval = setInterval(() => {
                //console.log('Попытка переподключения...')
                this.connect().then();
            }, 3000)
        }
    }

    send(message) {
        if (this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(message)
        } else {
            //console.error('Ошибка отправки сообщения: WebSocket соединение не установлено');
        }
    }

    close() {
        this.socket.close();

        this.stopReconnect()
    }

    addMessageHandler(handler) {
        if (!this.messageHandlers.includes(handler)) {
            this.messageHandlers.push(handler)
        }
    }

    removeMessageHandler(handler) {
        this.messageHandlers = this.messageHandlers.filter(h => h !== handler)
    }
}

const wsUrl = cfg._("base", "api_url_ws")

const socket = new WebSocketClient(wsUrl)

export default socket
