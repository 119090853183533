import store from "@/plugins/store"

const storeName = "statistic.sale"

function fill(items, typeId) {
    if (items.length === 0) {
        return false
    }

    let ids = []

    for (let key of Object.keys(items)) {
        ids.push(items[key]["id"])
    }

    setData({
        ids: ids,
        typeId: typeId,
    }).then(() => {})
}

function setData(ids, typeId) {
    return store.dispatch(storeName + "/setData", ids, typeId)
}

function getData() {
    return store.getters[storeName + "/getData"]
}

export {fill, getData, storeName}
