<template>
  <div @click="clickBlock()" id="post-image">
    <div class="card" :class="{'card-inactive': isDisable()}">
      <div class="card-header">
        <h3 class="card-title title">Картинка</h3>
        <template v-if="hasImage()">
          <div class="card-actions">
            <div @click="onDeleteImage()">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24"
                   height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                   stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M18 6l-12 12"></path>
                <path d="M6 6l12 12"></path>
              </svg>
            </div>
          </div>
        </template>
      </div>

      <div class="card-body">
        <template v-if="!hasImage()">
          <div class="fallback">
            <input type="file" @change="onImageChange"/>
          </div>
        </template>

        <template v-if="hasImage()">
          <div class="mt-3">
            <Lightbox :images="{thumb: getImage(320), origin: getImage()}"></Lightbox>
          </div>
        </template>

        <template v-if="isLoadImage()">
          <div class="mt-3">
            <div class="progress progress-sm">
              <div class="progress-bar progress-bar-indeterminate"></div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "./services/Api"
import {defineComponent, ref, watch} from "vue"
import Lightbox from "@/components/lightbox/Lightbox"
import Image from "@/services/Image"
import Notify from "@/services/Notify"

export default defineComponent({
  name: "Image",
  components: {
    Lightbox
  },
  props: {
    postId: {
      type: Number,
    },
    src: {
      type: Object,
    },
    hasVideo: {
      type: Boolean,
      required: true,
    }
  },
  setup(props, {emit}) {
    let postId = ref(props.postId)
    let image = ref(props.src)
    let loadImage = ref(false)
    let hasVideo = ref(props.hasVideo)

    watch(() => props.hasVideo, (val) => {
      hasVideo.value = val
    })

    watch(() => props.postId, (val) => {
      postId.value = val
    })

    function isActive() {
      return hasPostId()
    }

    function hasPostId() {
      return postId.value !== null
    }

    function hasImage() {
      return image.value !== null
    }

    function deleteImage() {
      deleteImageApi(getPayload({
        post_id: postId.value,
        image_id: image.value["id"],
      })).then(() => {
        return image.value = null
      })
    }

    function onDeleteImage() {
      if (confirm("Вы действительно хотите удалить картинку?")) {

        deleteImage()

        emit("onDelete")
      }
    }

    function isLoadImage() {
      return loadImage.value === true
    }

    function getImage(w = null, h = null) {
      return [
        Image.handle(image.value["host"], image.value["name"], w, h)
      ]
    }

    function onImageChange(e) {
      change(e)
    }

    function change(e) {
      loadImage.value = true

      if (e.target.files[0].size > 5242880) {
        Notify.error("Размер картинки не должен превышать 5мб");
        loadImage.value = false
        return;
      }

      uploadImageApi(getPayload({
        post_id: postId.value,
        image: e.target.files[0],
      })).then((resp) => {
        setImage({
          "host": resp["host"],
          "name": resp["name"],
          "id": resp["image_id"],
        })

        emit("onChange", {
          host: resp["host"],
          name: resp["name"],
        })
      }).finally(() => {
        loadImage.value = false
      })
    }

    function setImage(val) {
      image.value = val
    }

    function uploadImageApi(payload) {
      return Api.addImage(payload)
    }

    function deleteImageApi(payload) {
      return Api.deleteImage(payload)
    }

    function getPayload(obj) {
      const formData = new FormData()

      Object.entries(obj).forEach(([key, val]) => {
        formData.append(key, val)
      })

      return formData
    }

    function isDisable() {
      return (!isActive() && !hasImage()) || hasVideo.value;
    }

    function clickBlock() {
      if (hasVideo.value) {
        alert("Так как загружено видео, картинку загрузить нельзя");
      }
    }

    return {
      isActive,
      hasImage,
      onDeleteImage,
      isLoadImage,
      getImage,
      onImageChange,
      isDisable,
      clickBlock,
    }
  }
})
</script>

<style scoped>
#post-image .title {
  font-size: 0.9rem;
}
</style>
