<template>
  <div class="badge bg-blue text-blue-f" v-if="count > 0">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" class="icon icon-tabler icons-tabler-filled icon-tabler-message">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <path d="M18 3a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-4.724l-4.762 2.857a1 1 0 0 1 -1.508 -.743l-.006 -.114v-2h-1a4 4 0 0 1 -3.995 -3.8l-.005 -.2v-8a4 4 0 0 1 4 -4zm-4 9h-6a1 1 0 0 0 0 2h6a1 1 0 0 0 0 -2m2 -4h-8a1 1 0 1 0 0 2h8a1 1 0 0 0 0 -2" />
    </svg>
    {{ count }}
  </div>
</template>

<script>
import {defineComponent, ref, toRaw, watch} from "vue"
import StoreRegister from "@/plugins/store/services/StoreRegister"
import stores from "./stores"
import {getData as commentCountGetData, storeName as commentCountStoreName} from "./services/base"

StoreRegister.handle(commentCountStoreName, stores)

export default defineComponent({
  name: "CommentCount",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    let count = ref(0)

    watch(() => commentCountGetData(), (val) => {
      render(toRaw(val))
    })

    function render(payload) {
      if (payload[props.data["channel_id"]] !== undefined) {
        count.value = payload[props.data["channel_id"]]
      }
    }

    return {
      count,
    }
  }
})
</script>

<style scoped>

</style>