<template>
  <template v-if="isShowComponent()">
    <div class="card" id="post-ref-links">
      <div class="card-header">
        <h3 class="card-title">Ссылки</h3>
      </div>
      <div class="table-responsive">
        <table class="table table-vcenter card-table table-striped">
          <thead>
          <tr>
            <th width="50%">Текст</th>
            <th width="15%">Канал</th>
            <th width="15%">Дата создания</th>
            <th width="15%">Автор</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item of getItems()" :key="item.id">
            <td>
              <a target="_blank" :href="row.getLinkPartner(item)">{{ row.getLinkPartner(item) }}</a>
              <span class="create-info">
                <template v-if="row.isCreateBot(item)">(бот)</template>
              </span>
            </td>
            <td class="channel">
              <img width="20" :src="row.getChannelImage(item)" alt="">
              <template v-if="row.hasPostId(item)">
                <router-link :to="{name: 'Update', params: {id: row.getPostId(item)}}">{{
                    row.getChannelName(item)
                  }}</router-link>
              </template>
              <template v-else>
                {{ row.getChannelName(item) }}
              </template>
            </td>
            <td v-html="row.getCreatedAt(item)" class="datetime"></td>
            <td class="user">
              <img width="20" :src="row.getUserAvatar(item, 40, 40)">{{ row.getPostAuthorName(item) }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>

  <template v-if="!isReadyPage()">
    <div class="text-center p-1">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </template>
</template>

<script>
import "./css/style.css"
import {defineComponent, onMounted, ref} from "vue"
import List from "@/modules/post/components/ref_links/services/Api"
import Image from "@/services/Image"
import element from "@/modules/post/components/ref_links/services/element"
import Helper from "@/services/Helper"
import DTBackend from "@/services/DTBackend"

export default defineComponent({
  name: "RefLinks",
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  setup(props) {
    let onlySelfPost = ref(false)
    let readyPage = ref(false)
    let postId = props.data["postId"]
    let items = ref([])
    let row = {
      getCreatedAt: (item) => {
        let result, dt, createdAt = element.getCreatedAt(item)

        if (createdAt === null) {
          result = "Не опубликован"
        } else {
          dt = new DTBackend(createdAt)
          result = dt.get("%D.%M.%y <span>%H:%I</span>")
        }

        return result
      },

      getTitle: () => {
        let title = "Post Telegram"

        return title
      },

      getChannelName: (item) => {
        let sourceId = element.getLinkSourceId(item)

        if (sourceId === -1) {
          return "Не запартнёрена"
        } else {
          let title = element.getChannelName(item)
          return title
        }
      },

      getChannelImage: (item) => {
        let host = element.getChannelImageHost(item)
        let name = element.getChannelImageName(item)

        if (host === null || name === null) {
          throw Error("Отсутствует иконка")
        }

        return Image.handle(host, name, 20, 20)
      },

      getUserAvatar: (item, w = null, h = null) => {
        let host = element.getAuthorAvatarHost(item)
        let name = element.getAuthorAvatarName(item)

        return Image.handle(host, name, w, h)
      },

      getLinkPartner: (item) => {
        return element.getLinkPartner(item)
      },

      getPostId: (item) => {
        return element.getPostId(item)
      },

      getPostAuthorName: (item) => {
        return element.getPostAuthorName(item)
      },

      hasPostId: (item) => {
        return element.getPostId(item) !== null
      },

      isCreateBot: (item) => {
        return element.getLinkSourceId(item) === 3
      },
    }

    onMounted(() => {
      init()
    })

    function init() {
      List.links(postId).then((resp) => {
        setItems(resp)
      }).finally(() => {
        readyPageOn()
      })
    }

    function isShowComponent() {
      return isReadyPage() && hasItems()
    }

    function isReadyPage() {
      return readyPage.value === true
    }

    function readyPageOn() {
      readyPage.value = true
    }

    function setItems(val) {
      items.value = val
    }

    function getItems() {
      return items.value
    }

    function hasItems() {
      return !Helper.isEmptyObject(items.value)
    }

    return {
      getItems,
      element,
      row,
      isShowComponent,
      isReadyPage,
      onlySelfPost,
    }
  }
})
</script>

<style scoped>

</style>
