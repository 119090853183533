<template>
  <div id="shops-list-component">
    <div class="cards row row-cards">
      <template v-if="isNotFound(items) && !busy">
        <span class="items-not-found">Ничего не найдено</span>
      </template>
      <template v-else>
        <div class="col-md-6 col-lg-3" v-for="item in items" :key="row.getId(item)" :class="{'no-active': !row.getActive(item)}">
          <router-link class="card" :to="{name: 'PromopushkaCouponItemsShop', params: { id: item.id }}">
            <div class="card-body p-4 text-center">
              <span class="avatar avatar-xl mb-3" :style="{ backgroundImage: 'url(' + row.getImage(item, 300) + ')' }">
                <span class="badge bg-red">{{ shopCoupons[item.id] || 0 }}</span>
              </span>
              <h3 class="m-0 mb-2">
                <span>{{ row.getTitle(item) }}</span>
              </h3>
              <div class="text-secondary mb-2">{{ row.getDesc(item) }}</div>
              <div class="mb-2">
                <span class="badge bg-blue-lt">{{ row.getCategoryName(item) }}</span>
              </div>
            </div>
          </router-link>
        </div>
      </template>
      <div class="loader-posts">
        <div class="spinner-border" v-show="busy"></div>
      </div>
    </div>
  </div>
</template>

<script>
import "./css/style.css"
import {defineComponent, ref, watch} from "vue"
import element from "@/modules/promopushka/pages/shops/components/list/services/element"
import ShopImage from "@/modules/promopushka/pages/shops/components/list/services/ShopImage"
import Helper from "@/services/Helper"
import Api from "./services/Api"

export default defineComponent({
  name: "List",
  components: {
  },
  props: {
    query: {
      type: Object,
      required: true
    },
    clearResult: {
      type: Boolean,
      required: true
    },
    shopCoupons: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, {emit}) {
    let busy = ref(false)
    let items = ref([])
    let eofItems = ref(false)
    let countItems = ref(0)

    let row = {
      getId: (item) => {
        return element.getId(item)
      },

      getTitle: (item) => {
        return element.getTitle(item)
      },

      getDesc: (item) => {
        return element.getDesc(item)
      },

      getCategoryId: (item) => {
        return element.getCategoryId(item)
      },

      getCategoryName: (item) => {
        return element.getCategoryName(item)
      },

      getImage: (item, w) => {
        let image = element.getImage(item)

        if (image !== null) {
          return getImage(image["host"], image["name"], w)
        } else {
          return getImage(null, null, w)
        }
      },

      getActive: (item) => {
        return element.getActive(item)
      },
    }

    function isNotFound(items) {
      return Helper.isEmptyObject(items)
    }

    function getImage(host, name, w = null, h = null) {
      return ShopImage.handle(host, name, w, h)
    }

    watch(() => props.query, (query) => {
      busy.value = true

      if (props.clearResult) {
        clearResult()
      }

      load(query)
    })

    function clearResult() {
      eofItems.value = false
      countItems.value = 0
      items.value = []
    }

    function load(query, offset = null) {
      if (offset !== null) {
        query.offset = offset;
      }

      Api.shops(query).then((resp) => {
        let data = resp.data

        items.value.push(...data.items)

        if (data.items.length === 0) {
          eofItems.value = true
        }

        countItems.value = data.count
      }).finally(() => {
        busy.value = false

        emit("onRender", {
          "oef_items": eofItems.value,
          "count_items": countItems.value
        })
      })
    }

    /*function reload() {
      clearResult()
      load(props.query, 0)
    }*/

    return {
      busy,
      items,
      row,
      isNotFound,
    }
  }
})
</script>
