<template>
  <metainfo></metainfo>

  <div class="page-header d-print-none">
    <div class="container-xl">
      <div class="row g-2 align-items-center">
        <div class="col">
          <h2 class="page-title">
            Каналы
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div class="page-body">
    <div class="container-xl">
      <div class="row row-cards">
        <div class="col-lg-4" v-for="channel of channels" :key="channel.id">
          <router-link class="card" :to="{name: 'Posts', params: {channel_id: channel.id}}">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-auto">
                  <template v-if="channel.id === 1">
                    <span class="avatar avatar-xl avatar-rounded"></span>
                  </template>
                  <template v-else>
                    <span class="avatar avatar-xl avatar-rounded" :style="{backgroundImage: 'url(/assets/static/channels/'+ channel.img +')'}"></span>
                  </template>
                </div>
                <div class="col">
                  <h3 class="card-title mb-1">
                    <div class="text-reset">{{ channel.name }}</div>
                    <CommentCount :data="{
                      channel_id: channel.id
                    }"/>
                  </h3>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-4">
          <router-link class="card" :to="{name: 'PromopushkaCouponItems'}">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="avatar avatar-xl avatar-rounded" :style="{backgroundImage: 'url(/assets/static/promopushka/logo.jpg)'}"></span>
                </div>
                <div class="col">
                  <h3 class="card-title mb-1">
                    <div class="text-reset">Промопушка</div>
                    <CommentCount :data="{
                      channel_id: 13,
                    }"/>
                  </h3>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommentCount from "../components/comment_count/CommentCount"
import {defineComponent, onMounted} from "vue"
import {fill as commentCountFill} from "../components/comment_count/services/base"
import {useMeta} from "vue-meta"

export default defineComponent({
  name: "Channels",
  components: {
    CommentCount,
  },
  setup() {
    useMeta({title: "Каналы"})


    let channels = [
      /*{
        name: "Сайт",
        img: "hlv.jpg",
        id: 1,
      },*/
      {
        name: "Молния",
        img: "molniya.jpg",
        id: 3,
      },
      {
        name: "Aliexpress",
        img: "ali.jpg",
        id: 4,
      },
      {
        name: "Халявщики",
        img: "hlv.jpg",
        id: 2,
      },
      {
        name: "Travel",
        img: "travel.jpg",
        id: 7,
      },
      {
        name: "Live",
        img: "live.jpg",
        id: 9,
      },
      {
        name: "Нашли дешевле",
        img: "cheaper.jpg",
        id: 8,
      },
      {
        name: "DailySales",
        img: "daily.jpg",
        id: 5,
      },
      {
        name: "Эконом",
        img: "econom.jpg",
        id: 10,
      },
      {
        name: "Haunt",
        img: "haunt.jpg",
        id: 11,
      },
      {
        name: "Ultima",
        img: "ultima.jpg",
        id: 12,
      },
    ]

    onMounted(() => {
      loadFinal()
    })

    function loadFinal() {
      commentCountFill([
        1,2,3,4,5,6,7,8,9,10,11,12,13,
      ], {
        only_moderate: true,
      })
    }

    return {
      channels,
    }
  }
})
</script>

<style scoped>

</style>
