import Helper from "@/services/Helper"

export default {
    status: {
        isPublished: (item) => {
            return item["status"] === "published"
        },
        isScheduled: (item) => {
            return item["status"] === "scheduled"
        },
        isDeleted: (item) => {
            return item["status"] === "deleted"
        },
        isScheduledRemoved: (item) => {
            return Helper.isString(item["scheduled_removed"])
        },
        isPrePublished: (item) => {
            return item["status"] === "prePublished"
        },
        isPreDeleted: (item) => {
            return item["status"] === "preDeleted"
        },
        isCanceled: (item) => {
            return item["status"] === "canceled"
        },
        isDraft: (item) => {
            return item["status"] === "draft"
        },
    },
    getId: (item) => {
        return item["id"]
    },
    getCreatedAt: (item) => {
        return item["created_at"]
    },
    getRemovedAt: (item) => {
        return item["removed_at"]
    },
    getScheduledPublished: (item) => {
        return item["scheduled_published"]
    },
    getScheduledRemoved: (item) => {
        return item["scheduled_removed"]
    },
    getPublishedAt: (item) => {
        return item["published_at"]
    },
    getChannelId: (item) => {
        return item["channel_id"]
    },
    getTypeCommerce: (item) => {
        return item["type_commerce"]
    },
    getHasVideo: (item) => {
        return item["has_video"]
    },
    getStatus: (item) => {
        return item["status"]
    },
    imagePosition: {
        isTop: (item) => {
            return item["image_position"] === 1
        },
        isBottom: (item) => {
            return item["image_position"] === 2
        },
        isNone: (item) => {
            return item["image_position"] === null
        },
    },
    hasImage: (item) => {
        return item["image_host"] !== null && item["image_name"] !== null
    }
}
