<template>
  <metainfo></metainfo>

  <div class="container-xl">
    <div class="page-header d-print-none">
      <div class="row g-2 align-items-center">
        <div class="col-sm">
          <h2 class="page-title">
            Комментарии
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div class="page-body">
    <div class="container-xl">
      <div class="mb-3">
        <CommentFilter @onFilter="onFilter" :payload="filter.payload"/>
      </div>
      <div class="mb-3">
        <CommentModeration :payload="query"/>
      </div>
    </div>
  </div>
</template>

<script>
import CommentModeration from "@/components/comment_moderation/CommentModeration"
import CommentFilter from "@/modules/comment/components/filter/Filter"
import Helper from "@/services/Helper"
import {ref} from "vue"
import {useMeta} from "vue-meta"

export default {
  name: "Comment",
  components: {
    CommentModeration,
    CommentFilter,
  },
  setup() {
    useMeta({title: "Комментарии"})

    let query = ref({})

    let filter = {
      payload: {
        search: "",
        date: null,
        users: [],
        channels: [],
      },
    }

    function onFilter(payload) {
      payload = Helper.mergeObject(payload, {
        categories: ['all', 'new', 'approved', 'rejected', 'removed'],
        limit: 50,
        active_category: 'new',
        force_show: true,
        filter: {
          user_ids: payload.users ?? [],
          channels: payload.channels ?? [],
          from_date: payload.date?.from ?? "",
          to_date: payload.date?.to ?? "",
          query_string: payload.search ?? "",
        },
      })

      payload = Helper.clone(payload)

      query.value = payload
    }

    return {
      onFilter,
      filter,
      query,
    }
  },
}
</script>

<style scoped>

</style>
