import title from "./title/index"
import AuthStore from "@/modules/auth/services/Store"
import {watch} from "vue"

export default {
    install: (app) => {
        watch(() => AuthStore.isAuth(), (isAuth) => {
            if (isAuth) {
                app.use(title)
            }
        })
    }
}
