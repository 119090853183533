<template>
  <div class="modal fade" :id="props.idModals" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Ответ на жалобы</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-for="item in props.complaints" :key="item.id" class="m-0">

            <h4>{{ item.author }}</h4>
            <p>{{ item.message }}</p>
            <hr>
          </div>
          <label class="form-label">Ответ</label>
          <textarea class="form-control" :class="{ 'is-invalid': errMes }" rows="3" v-model="answer"></textarea>
          <div class="invalid-feedback">{{ errMes }}</div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
          <button type="button" class="btn btn-primary" @click="send">Ответить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue'
const props = defineProps(['idModals', 'complaints'])
const answer = ref('')
const emit = defineEmits(['send'])
let errMes = ref('');

function send() {
  if (answer.value) {
    emit('send', answer.value)
    answer.value = ''
    errMes.value = ''
  } else {
    errMes.value = 'Необходимо заполнить'
  }
}


</script>