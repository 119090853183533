<template>
  <Posts :object="object" :params="params" :key="version" />
</template>

<script>
import {defineComponent, onMounted, ref} from "vue"
import Posts from "@/modules/post/pages/posts/components/posts/Posts"
import Default from "@/modules/post/pages/posts/services/Default"

export default defineComponent({
  name: "Default",
  components: {
    Posts
  },
  setup() {
    let version = ref(0)

    let object = new Default()

    let params = {
      title: "Все посты",
      h1: "Посты",
      page: "posts",
    }

    onMounted(() => {
      version.value++
    })

    return {
      object,
      params,
      version,
    }
  }
})
</script>

<style scoped>

</style>
