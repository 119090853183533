<template>
  <button class="btn btn-green w-100 mb-3" @click="onRepublished">
    <span class="spinner-border spinner-border-sm me-2" v-show="isShowLoadingBtn()"></span>
    Повторить публикацию
  </button>
</template>

<script>
import {defineComponent, ref} from "vue"
import Api from "./services/Api"
import Notify from "@/services/Notify"

export default defineComponent({
  name: "Republished",
  props: {
    query: {
      type: Object,
      required: true,
    },
  },
  setup(props, {emit}) {
    let publishing = ref(false)

    function onRepublished() {
      if (confirm("Вы действительно хотите повторить публикацию?")) {
        startRepublishing()

        let query = prepareQuery(props["query"])

        Api.republished(query).then(() => {
          emit('onRepublished', true)
        }).then(() => {
          Notify.success("Поставлена задача на повтор публикации.")
        }).catch(() => {
          emit('onRepublished', false)
        }).finally(() => {
          stopRepublishing()
        })
      }
    }

    function prepareQuery(q) {
      return {
        "post_id": getItem(q, "post_id"),
        "content": getItem(q, "content"),
        "image": getItem(q, "image"),
        "image_position": getItem(q, "image_position"),
        "scheduled_removed": getItem(q, "scheduled_removed"),
        "scheduled_published": getItem(q, "scheduled_published"),
        "button": getItem(q, "button"),
        "is_partner_links": getItem(q, "is_partner_links"),
        "comment_allow": getItem(q, "comment_allow"),
        "shop_id": getItem(q, "shop_id"),
        "type_commerce": getItem(q, "type_commerce"),
      };
    }

    function getItem(q, key) {
      return q?.[key] !== undefined ? q?.[key] : null
    }

    function isDisabledBtn() {
      return publishing.value === true
    }

    function isShowLoadingBtn() {
      return isDisabledBtn()
    }

    function startRepublishing() {
      publishing.value = true
    }

    function stopRepublishing() {
      publishing.value = false
    }

    return {
      onRepublished,
      isDisabledBtn,
      isShowLoadingBtn,
    }
  },
})
</script>

<style scoped>

</style>
