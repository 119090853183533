<template>
  <metainfo></metainfo>
  <div class="container-xl">
    <div class="page-header d-print-none">
      <div class="row g-2 align-items-center">
        <div class="col-sm">
          <h2 class="page-title">Ранжирование</h2>
        </div>
      </div>
    </div>
  </div>
  <div class="page-body">
    <div class="container-xl">
      <div ref="scrollComponent">
        <PostList :query="query" @onRender="onRender" :clear-result="clearResult" />
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref, onMounted, onUnmounted} from "vue"
import PostList from "@/modules/post/components/list/List"
import Helper from "@/services/Helper"
import {useMeta} from "vue-meta"
import PostStoreCommon from "@/modules/post/pages/posts/services/PostStoreCommon"

export default defineComponent({
  name: "Ranking",
  components: {
    PostList,
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    useMeta({title: "Ранжирование"})

    onMounted(() => {
      onFilter({})
    })

    let postFilter = {
      params: {
        search: "",
        date: null,
        users: [],
        statuses: {
          published: true,
          scheduled: true,
          removed: false,
          draft: false,
        },
      },
      saveForm: true,
      enable: true,
    }

    let query = ref({})
    let clearResult = ref(false)
    let offset = ref(0)
    let eofResult = ref(false)
    let countPosts = ref(0)

    function onRender(payload) {
      eofResult.value = payload["oef_items"] === true
      if (payload["count_items"] > 0) {
        countPosts.value = payload["count_items"];
      }
      clearResult.value = false
      isLoading.value = false
    }

    function onFilter(q) {
      clearResult.value = true
      offset.value = 0
      eofResult.value = false
      countPosts.value = 0

      q = Helper.mergeObject(q, {
        "limit": 18,
        "offset": offset.value,
        "page": "ranking",
      })

      query.value = q
    }

    function onLoadMore(offset) {
      if (Helper.isEmptyObject(query.value)) {
        return false
      }

      let q = Helper.clone(query.value)

      query.value = Helper.mergeObject(q, {
        "offset": offset,
      })
    }

    onMounted(() => {
      PostStoreCommon.handle({
        "channel_id": null,
        "route_name": "Ranking",
      })

      window.addEventListener("scroll", handleScroll)
    })

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll)
    })

    const scrollComponent = ref(null)
    let isLoading = ref(false)

    let scroll = () => {
      isLoading.value = true
      offset.value += 18

      onLoadMore(offset.value)
    }

    const handleScroll = () => {
      if (eofResult.value) return

      let element = scrollComponent.value

      if (!isLoading.value && element.getBoundingClientRect().bottom < window.innerHeight) {
        scroll()
      }
    }

    function getCurrentPage() {
      return props.params.page
    }

    return {
      onFilter,
      query,
      scrollComponent,
      onRender,
      clearResult,
      postFilter,
      getCurrentPage,
      countPosts,
    }
  },
})
</script>

<style scoped>

</style>
