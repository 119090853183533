import PostApiBase from "@/modules/post/services/ApiBase"
import ConvertQuery from "@/modules/post/components/list/services/ConvertQuery"
import Helper from "@/services/Helper"

export default class Api {
    static postsChannel(query) {
        let q = ConvertQuery.handle(query)

        return PostApiBase.postsChannel(q)
    }

    static posts(query) {
        let q = ConvertQuery.handle(query)

        return PostApiBase.posts(q)
    }

    static delete(postId) {
        return PostApiBase.delete({
            post_id: postId,
        }).then((resp) => {
            return resp.data
        })
    }

    static statisticRanking(q) {
        let query = Helper.mergeObject(q, {
            "limit": q["limit"],
            "offset": q["offset"],
        })

        return PostApiBase.statisticRanking(query)
    }
}
