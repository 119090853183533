<template>
    <div v-if="getUser().id">
        <div class="hr-text">Аватар</div>

        <div class="mb-3">
            <div class="row">
                <div class="col-auto" v-if="getImage().host">
                    <img :src="renderImage(getImage().host, getImage().name, 60)" alt="">
                </div>
                <div class="col-lg-6">
                    <div class="form-label">Фото</div>
                    <input type="file" class="form-control" accept=".png, .jpg, .jpeg, .gif" @change=handleImage>
                </div>
                <div class="col-auto ms-auto">
                    <div class="form-label">&nbsp;</div>
                    <button class="btn btn-danger me-1" v-if="getImage().host" @click=deleteImage>Удалить</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import {defineComponent, onMounted, ref} from "vue"
import Image from "@/services/Image"
import Api from "@/modules/user/components/edit/services/Api"
import Notify from "@/services/Notify"
  
export default defineComponent({
    name: "Avatar",
    components: {
    },
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    setup(props) {//, {emit}
        let user = ref({})
        let image = ref({})

        onMounted(() => {
            user.value = props.user
            setImage(props.user.avatar_host, props.user.avatar_name)
        })

        function getUser() {
            return user.value
        }

        function getImage() {
            return image.value
        }

        function setImage(host, name) {
            image.value = {
                host: host,
                name: name
            }
        }
    
        function renderImage(host, name, w = null, h = null) {
            return Image.handle(host, name, w, h)
        }

        function handleImage(e) {
            const file = e.target.files[0];
            if (!file) return;

            if (!file.type.match('image.*')) {
              Notify.error('Выберите картинку!')
                return;
            }

            const reader = new FileReader();

            reader.onload = (e) => {
                const image = document.createElement('img');
                image.src = e.target.result;

                image.onload = () => {
                    const width = image.width;
                    const height = image.height;
                    document.body.removeChild(tempDiv);
                    
                    if (width !== height) {
                      Notify.error('Выберите квадратную картинку!')
                    } else {
                        uploadImage(file)
                    }
                };

                const tempDiv = document.createElement('div');
                tempDiv.style.display = 'none'
                tempDiv.appendChild(image);
                document.body.appendChild(tempDiv);
            };

            reader.readAsDataURL(file);
        }

      function uploadImage(image) {
        let data = new FormData()
        data.append('user_id', getUser().id)
        data.append('image', image)

        Api.userAvatar(data).then((resp) => {
          setImage(resp.item.user_avatar_host, resp.item.user_avatar_name)
          Notify.success("Аватар загружен");
        }).catch((err) => {
          Notify.error('Не удалось загрузить аватар', err)
        })
      }

        function deleteImage() {
          if (!getImage().host) return false;

          Api.userAvatarDelete(getUser().id).then(() => {
            setImage(false, false)
            Notify.success("Аватар удален");
          }).catch((err) => {
            Notify.error('Не удалось удалить аватар', err)
          })
        }

        return {
            getImage,
            handleImage,
            renderImage,
            deleteImage,
            getUser
        }
    }
})
</script>
  
<style scoped>
#user-edit .hr-text::after, #user-edit .hr-text::before {
    height: 1px;
}
</style>
  