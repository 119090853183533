<template>
  <button class="btn btn-twitter w-100 mb-3" :class="{'disabled': isPending()}" @click="onHandle">
    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4"></path>
    </svg>
    <span class="spinner-border spinner-border-sm me-2" v-show="isPending()"></span>
    Посмотреть в боте
  </button>
</template>

<script>
import {defineComponent, ref} from "vue"
import Api from "@/modules/post/components/see_in_bot/services/Api"
import Notify from "@/services/Notify"

export default defineComponent({
  name: "SeeInBot",
  props: {
    postId: {
      type: Number,
      required: true
    },
    query: {
      type: Object,
      required: true,
    },
  },
  setup(props, {emit}) {
    let pending = ref(false)

    function onHandle() {
      startPending()

      let query = prepareQuery(props["query"])

      Api.seeInBot(query).then(() => {
        Notify.success("Ожидайте сообщения от бота")
        emit("onSuccess", {})
      }).catch((resp) => {
        let validations = resp.response.data?.validations !== undefined
            ? resp.response.data?.validations
            : {}

        emit("onError", validations)
      }).finally(() => {
        stopPending()
      })
    }

    function prepareQuery(q) {
      return {
        "post_id": getItem(q, "post_id"),
        "content": getItem(q, "content"),
        "image": getItem(q, "image"),
        "image_position": getItem(q, "image_position"),
        "scheduled_removed": getItem(q, "scheduled_removed"),
        "scheduled_published": getItem(q, "scheduled_published"),
        "button": getItem(q, "button"),
        "is_partner_links": getItem(q, "is_partner_links"),
        "comment_allow": getItem(q, "comment_allow"),
      };
    }

    function getItem(q, key) {
      return q?.[key] !== undefined ? q?.[key] : null
    }

    function startPending() {
      pending.value = true
    }

    function stopPending() {
      pending.value = false
    }

    function isPending() {
      return pending.value === true
    }

    return {
      onHandle,
      isPending,
    }
  },
})
</script>

<style scoped>

</style>
