<template>
  <div id="count-comment-moderation" v-if="hasCount()">
    <div class="alert alert-danger alert-dismissible" role="alert" @click="onClick()">
      <div class="d-flex">
        Комментариев на проверке: {{ count }}
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue"
import { useRouter } from 'vue-router'
import Api from "./services/Api"

export default defineComponent({
  name: "CountCommentModeration",
  components: {},
  props: {
    payload: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter()
    let payload = geyPayload()
    let count = ref(0)

    onMounted(() => {
      getCount({
        channel_ids: payload.channelId,
        is_moderate: false,
        is_reject: false,
        is_delst: false,
      }).then((resp) => {
        if (resp[payload.channelId] === undefined) {
          return
        }

        count.value = resp[payload.channelId]
      })
    })

    function onClick() {
      router.push({name: "IndexComment", query: {"channel_id": payload.channelId}})
    }

    async function getCount(query) {
      return await Api.count(query)
    }

    function geyPayload() {
      let channelId = null

      if (typeof props.payload.channel_id === "number") {
        channelId = props.payload.channel_id
      }

      return {
        channelId,
      }
    }

    function hasCount() {
      return count.value > 0
    }

    return {
      onClick,
      count,
      hasCount,
    }
  },
})
</script>

<style scoped>
#count-comment-moderation .alert {
  font-size: 16px
}
#count-comment-moderation .alert:hover {
  cursor: pointer;
}
</style>