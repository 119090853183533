import Helper from "@/services/Helper"

export default class ConvertQuery {
    static handle(query) {
        let q = {}
        let statuses = []

        if (query["date"]["from"] !== null) {
            q = Helper.mergeObject(q, {
                "from_date": query["date"]["from"],
            })
        }

        if (query["date"]["to"] !== null) {
            q = Helper.mergeObject(q, {
                "to_date": query["date"]["to"],
            })
        }

        if (query["users"].length > 0) {
            q = Helper.mergeObject(q, {
                "user_id": query["users"],
            })
        }

        if (query["shops"].length > 0) {
            q = Helper.mergeObject(q, {
                "shop_id": query["shops"],
            })
        }

        if (query["search"] !== "") {
            q = Helper.mergeObject(q, {
                "query_string": query["search"],
            })
        }

        if (query.statuses?.enabled) {
            statuses.push(10)
        }

        if (query.statuses?.disabled) {
            statuses.push(0)
        }

        if (query.statuses?.removed) {
            statuses.push(1)
        }

        if (statuses.join(",")) {
            q = Helper.mergeObject(q, {
                "statuses": statuses.join(","),
            })
        }

        return Helper.mergeObject(q, {
            "limit": query["limit"],
            "offset": query["offset"],
        })
    }
}