import Api from "../services/Api"
import {ref} from "vue"
import Helper from "@/services/Helper"

export default {
    namespaced: true,
    state: {
        data: ref({}),
    },
    getters: {
        getData: (state) => () => {
            return state.data
        },
    },
    mutations: {
        setData: (state, payload) => {
            state.data = payload["data"]
        },
    },
    actions: {
        setData(state, payload) {
            let options = payload?.options
            let query = {}

            query = Helper.mergeObject(query, {
                channel_ids: payload["ids"],
            })

            if (options?.only_moderate) {
                query = Helper.mergeObject(query, {
                    is_moderate: false,
                    is_reject: false,
                    is_delst: false,
                })
            }
            
            let resp = new Promise((resolve) => {
                Api.count(query).then((resp) => {
                    state.commit('setData', {
                        data: resp,
                    })
                }).finally(() => {
                    resolve()
                })
            });

            return Promise.all([resp])
        }
    },
}
