<template>
    <metainfo></metainfo>
    <div class="page-header d-print-none">
      <div class="container-xl">
        <div class="row g-2 align-items-center">
          <div class="col">
            <h2 class="page-title">
              Магазины
            </h2>
          </div>
          <div class="col-auto">
              <router-link class="btn btn-primary" :to="{name: 'PromopushkaCouponCreate'}">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <circle cx="12" cy="12" r="9"></circle>
                  <line x1="9" y1="12" x2="15" y2="12"></line>
                  <line x1="12" y1="9" x2="12" y2="15"></line>
                </svg>
                Создать скидку
              </router-link>
          </div>
          <div class="col-auto">
            <router-link class="btn btn-dark" :to="{name: 'PromopushkaCouponItems'}">
                Все скидки
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="container-xl">
        <div class="mb-3">
          <ShopFilter @onFilter="onFilter" :params="getParams()" />
        </div>
        <div ref="scrollComponent">
          <ShopList :query="getQuery()" @onRender="onRender" :clear-result="getClearResult()" :shopCoupons="shopCoupons || {}" />
        </div>
      </div>
    </div>
  </template>
  <script>
  import {defineComponent, onMounted, onUnmounted, ref} from 'vue'
  import ShopFilter from "@/modules/promopushka/pages/shops/components/filter/Index"
  import ShopList from "@/modules/promopushka/pages/shops/components/list/Index"
  import {useMeta} from "vue-meta"
  import Helper from "@/services/Helper"
  import Api from './services/Api'
  
  export default defineComponent({
    name: "Items",
    components: {
      ShopFilter,
      ShopList,
    },
    setup() {
      useMeta({title: "Магазины купонов"})
  
      const scrollComponent = ref(null)
  
      let query = ref({})
      let clearResult = ref(false)
      let offset = ref(0)
      let eofResult = ref(false)
      let countPosts = ref(0)
      let isLoading = ref(false)
      let shopCoupons = ref({})

      let scroll = () => {
        isLoading.value = true
        offset.value += 18
  
        onLoadMore(offset.value)
      }
      let params = {
        search: "",
        categories: [],
        enable: true,
      }
  
      function onRender(payload) {
        eofResult.value = payload["oef_items"] === true
        if (payload["count_items"] > 0) {
          countPosts.value = payload["count_items"];
        }
        clearResult.value = false
        isLoading.value = false
      }
  
      function onFilter(q) {
        clearResult.value = true
        offset.value = 0
        eofResult.value = false
        countPosts.value = 0
  
        q = Helper.mergeObject(q, {
          "limit": 18,
          "offset": offset.value,
        })
  
        q = Helper.clone(q)
  
        query.value = q
      }
  
      function onLoadMore(offset) {
        if (Helper.isEmptyObject(query.value)) {
          return false
        }
  
        let q = Helper.clone(query.value)
  
        query.value = Helper.mergeObject(q, {
          "offset": offset,
        })
      }
  
      const handleScroll = () => {
        if (eofResult.value) return
  
        let element = scrollComponent.value
  
        if (!isLoading.value && element.getBoundingClientRect().bottom < window.innerHeight) {
          scroll()
        }
      }
  
      onMounted(() => {
        getShopCoupons()
        window.addEventListener("scroll", handleScroll)
      })
  
      onUnmounted(() => {
        window.removeEventListener("scroll", handleScroll)
      })
  
      function getParams() {
        return params
      }
  
      function getQuery() {
        return query.value
      }
  
      function getClearResult() {
        return clearResult.value
      }
  
      function onUpdate() {
        onFilter({})
      }
  
      function onCreate() {
        onFilter({})
      }
  
      function onDelete() {
        onFilter({})
      }

      function getShopCoupons() {
        return Api.shopCoupons().then((resp) => {
            shopCoupons.value = resp.data.data.items
            console.log(shopCoupons.value)
        })
      }
  
      return {
        getParams,
        getQuery,
        getClearResult,
        onRender,
        onFilter,
        scrollComponent,
        onUpdate,
        onCreate,
        onDelete,
        shopCoupons
      }
    }
  })
  
  </script>
  
  