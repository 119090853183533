<template>
    <div class="card" id="send-to-tg">
      <div class="card-body">
        <div class="divide-y">
          <div class="row">
            <span class="col">
                <label class="form-label">Отправить в тг канал</label>
            </span>
            <span class="col-auto">
              <label class="form-check form-check-single">
                <input class="form-check-input" type="checkbox" v-model="isEnable" @change="onChange">
              </label>
            </span>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  import {defineComponent, ref, watch, onMounted} from "vue"
  
  export default defineComponent({
    name: "SendToTg",
    props: {
      data: {
        type: Object,
        default: null,
      },
      validations: {},
    },
    setup(props, {emit}) {
      let isEnable = ref(false)
  
      onMounted(() => {
        isEnable.value = props.data.enable === 1;
      })

      watch(() => props.data.enable, (val) => {
        isEnable.value = val === 1
      })
  
      function onChange() {
        let value = isEnable.value === true ? 1 : 0
        emit("onChange", value)
      }
  
      return {
        isEnable,
        onChange,
      }
    }
  })
  </script>
  
  <style scoped>
  
  </style>
  