<template>
  <div class="page page-center">
    <div class="container container-tight py-4">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
}
</script>
