<template>
  <div class="row">
    <div class="col-lg-12">
      <div>
        <label class="form-label">Ссылка на пост, который прикрепится к текущему посту</label>
        <input class="form-control" placeholder="Ссылка на пост" v-model="fields.bindPostLink">
      </div>
    </div>
    <span :class="{'is-invalid': v$.bindPostLink.$invalid && v$.bindPostLink.$dirty}"></span>
    <div class="invalid-feedback" v-for="error of v$.bindPostLink.$silentErrors" :key="error.$uid">
      {{ error.$message }}
    </div>
  </div>
</template>

<script>
import {defineComponent, ref, watch, reactive} from "vue"
import Api from "@/components/object_chains/services/Api"
import {required, url} from "@/plugins/vuelidator"
import {useVuelidate} from "@vuelidate/core"
import Url from "@/services/Url"
import Notify from "@/services/Notify"

export default defineComponent({
  name: "BindPost",
  props: {
    bind: {
      type: Boolean,
      required: true,
    },
    post_id: {
      type: Number,
      required: true,
    },
    type_id: {
      type: Number,
      required: true,
    },
  },
  setup(props, {emit}) {
    let postId = ref(props.post_id)
    let typeId = ref(props.type_id)
    let bindPostLink = ref("")
    let $externalResults = reactive({})

    const fields = reactive({
      bindPostLink: bindPostLink.value,
    })

    const rules = {
      bindPostLink: {
        required,
        url,
      },
    }

    const v$ = useVuelidate(rules, fields, {$externalResults, $autoDirty: true})

    watch(() => props.bind, () => {
      bind(postId.value, typeId.value)
    })

    watch(() => fields.bindPostLink, (val) => {
      clearValidate()
      fields.bindPostLink = val
    })

    function bind(postId, typeId) {
      resetValidations()

      if (hasValidationsAnyErrors()) {
        emit("bindFinally")
        return false
      }

      Api.bind(
          getPostId(fields.bindPostLink),
          postId,
          getTypeId(fields.bindPostLink),
          typeId,
      ).then(() => {
        Notify.success("Объект добавлен в цепочку")
        setBindPostLink("")
        resetValidations()
        emit("bindSuccess")
      }).catch((resp) => {
        let data = resp.response.data

        if (data?.validations !== undefined) {
          validationServer(getErrors(data.validations))
        }
      }).finally(() => {
        emit("bindFinally")
      })
    }

    function getPostId(link) {
      if (link === "") {
        return false
      }

      let url = new Url(link)

      let postId = url.getLastPart()

      if (postId === null) {
        throw Error("По ссылке неопределен PostID")
      }

      return parseInt(postId)
    }

    function getTypeId(link) {
      if (link === "") {
        return false
      }

      let url = new Url(link)

      let type = url.getPart(1)

      if (type === null) {
        throw Error("По ссылке неопределен Тип")
      }

      if (type === "post") {
        return 1;
      } else if (type === "promopushka") {
        return 2;
      } else {
        throw Error("Неверный тип")
      }
    }

    function getErrors(validations) {
      let result = []
      Object.values(validations).forEach(value => {
        result.push(value[0])
      })
      return result
    }

    function validate(fn) {
      clearValidate()
      return v$.value.$validate().then((resp) => {
        if (resp === false) {
          return false
        }
        fn()
      })
    }

    function hasValidationsAnyErrors() {
      return v$.value.$error === true
    }

    function resetValidations() {
      v$.value.$reset()
    }

    function clearValidate() {
      v$.value.$clearExternalResults()
    }

    function validationServer(errors) {
      validate(() => {
        Object.values(errors).forEach((val) => {
          $externalResults.bindPostLink = val
        })
      })
    }

    function setBindPostLink(val) {
      fields.bindPostLink = val
    }

    return {
      v$,
      fields,
    }
  },
})
</script>

<style scoped>

</style>
