<template>
  <div id="post-list-component">
    <div class="row row-cards">
      <template v-if="isNotFound(items) && !busy">
        <span class="items-not-found">Ничего не найдено</span>
      </template>
      <template v-else>
        <div class="col-lg-4 col-sm-6" v-for="item in items" :key="item['id']">
          <div class="card" :class="getClassBg(item)">

            <template v-if="row.isPrePublished(item) || row.isPreDeleted(item)">
              <div class="ribbon bg-warn">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-alert-circle-filled" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M12 2c5.523 0 10 4.477 10 10a10 10 0 0 1 -19.995 .324l-.005 -.324l.004 -.28c.148 -5.393 4.566 -9.72 9.996 -9.72zm.01 13l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-.01 -8a1 1 0 0 0 -.993 .883l-.007 .117v4l.007 .117a1 1 0 0 0 1.986 0l.007 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z" stroke-width="0" fill="currentColor"/>
                </svg>
                <span v-if="row.isPrePublished(item)">Публикуется...</span>
                <span v-if="row.isPreDeleted(item)">Удаляется...</span>
                <span v-if="row.isCanceled(item)">Отменен</span>
              </div>
            </template>

            <template v-if="row.isDraft(item)">
              <div class="ribbon bg-secondary">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-pencil" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                  <path d="M13.5 6.5l4 4"></path>
                </svg> <span v-html="row.getCreatedAt(item)"></span>
              </div>
            </template>

            <template v-if="row.isDeleted(item)">
              <div class="ribbon bg-red">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <line x1="4" y1="7" x2="20" y2="7"></line>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                </svg> <span v-html="row.getRemovedAt(item)"></span>
              </div>
            </template>

            <template v-else-if="row.isScheduled(item)">
              <div class="ribbon bg-yellow">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-clock-hour-8" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <circle cx="12" cy="12" r="9"></circle>
                  <path d="M12 12l-3 2"></path>
                  <path d="M12 7v5"></path>
                </svg> <span v-html="row.getScheduledPublished(item)"></span>
              </div>
            </template>

            <template v-else-if="row.isViewScheduledRemoved(item)">
              <div class="ribbon bg-flickr">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-flame" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12c2 -2.96 0 -7 -1 -8c0 3.038 -1.773 4.741 -3 6c-1.226 1.26 -2 3.24 -2 5a6 6 0 1 0 12 0c0 -1.532 -1.056 -3.94 -2 -5c-1.786 3 -2.791 3 -4 2z" /></svg>
                <span v-html="row.getScheduledRemoved(item)"></span>
              </div>
            </template>

            <template v-if="!row.hasImage(item)">
              <div class="close-full-show" :class="{ 'right': !hasBadge(item) }" v-if="isMoreShow(item['id'])" @click.prevent="onMoreHide(item['id'])">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M18 6l-12 12"></path>
                  <path d="M6 6l12 12"></path>
                </svg>
              </div>
              <span>
                <div class="card-img-top card-img-hover" @click.prevent="onMoreShowHide(item['id'])" :class="{ 'card-img-top_show': isMoreShow(item['id']) }">
                  <img :src="getImageStub(row.hasVideo(item))" class="img-card">
                  <div class="card-info">
                    <TypeCommerce :data="{
                      value: row.getTypeCommerce(item)
                    }" />
                    <PostSaleStatistic :data="{
                      post_id: row.getId(item),
                    }" />
                     <PostPointStatistic :data="{
                      post_id: row.getId(item),
                    }" />
                  </div>
                </div>
                <div class="card-body post-item" @click="onMoreShow(item['id'])">
                  <div class="text-muted text-long" v-show="isMoreShow(item['id'])" v-html="item['content_long']"></div>
                  <div class="text-muted card-text-sh" v-show="!isMoreShow(item['id'])" v-html="item['content_short']"></div>
                  <div class="my-2">
                    <template v-if="PostPermission.isUpdate(row.getChannelId(item))">
                      <router-link :to="{ name: 'Update', params: { id: item['id'] } }" class="edit-post toggle-show-post" @click.prevent="onMoreShow(item['id'])">Редактировать</router-link>
                    </template>
                    <template v-else>
                      <router-link :to="{ name: 'Post', params: { id: item['id'] } }" class="edit-post toggle-show-post" @click.prevent="onMoreShow(item['id'])">Смотреть</router-link>
                    </template>
                  </div>
                </div>
              </span>
            </template>

            <template v-else-if="row.isTopBlock(item) || row.isNoneBlock(item)">
              <div class="close-full-show" :class="{ 'right': !hasBadge(item) }" v-if="isMoreShow(item['id'])" @click.prevent="onMoreHide(item['id'])">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M18 6l-12 12"></path>
                  <path d="M6 6l12 12"></path>
                </svg>
              </div>
              <span>
                <div class="card-img-top card-img-hover" @click.prevent="onMoreShowHide(item['id'])" :class="{ 'card-img-top_show': isMoreShow(item['id']) }">
                  <img :src="getImage(item['image_host'], item['image_name'], null, 500)" class="img-card">
                  <div class="card-info">
                    <TypeCommerce :data="{
                      value: row.getTypeCommerce(item)
                    }" />
                    <PostSaleStatistic :data="{
                      post_id: row.getId(item),
                    }" />
                    <PostPointStatistic :data="{
                      post_id: row.getId(item),
                    }" />
                  </div>
                </div>

                <div class="card-body post-item" @click="onMoreShow(item['id'])">
                  <div class="text-muted text-long" v-show="isMoreShow(item['id'])" v-html="item['content_long']"></div>
                  <div class="text-muted card-text-sh" v-show="!isMoreShow(item['id'])" v-html="item['content_short']"></div>
                  <div class="my-2">
                    <template v-if="PostPermission.isUpdate(row.getChannelId(item))">
                      <router-link :to="{ name: 'Update', params: { id: item['id'] } }" class="edit-post toggle-show-post" @click.prevent="onMoreShow(item['id'])">Редактировать</router-link>
                    </template>
                    <template v-else>
                      <router-link :to="{ name: 'Post', params: { id: item['id'] } }" class="edit-post toggle-show-post" @click.prevent="onMoreShow(item['id'])">Смотреть</router-link>
                    </template>
                  </div>
                </div>
              </span>
            </template>

            <template v-else-if="row.isBottomBlock(item)">
              <div class="close-full-show" :class="{ 'right': !hasBadge(item) }" v-if="isMoreShow(item['id'])" @click.prevent="onMoreHide(item['id'])">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M18 6l-12 12"></path>
                  <path d="M6 6l12 12"></path>
                </svg>
              </div>
              <span>
                <div class="card-body post-item" @click="onMoreShow(item['id'])">
                  <div class="text-muted text-long" v-show="isMoreShow(item['id'])" v-html="item['content_long']"></div>
                  <div class="text-muted card-text-sh" v-show="!isMoreShow(item['id'])" v-html="item['content_short']"></div>
                  <div class="my-2">
                    <template v-if="PostPermission.isUpdate(row.getChannelId(item))">
                      <router-link :to="{ name: 'Update', params: { id: item['id'] } }" class="edit-post toggle-show-post" @click.prevent="onMoreShow(item['id'])">Редактировать</router-link>
                    </template>
                    <template v-else>
                      <router-link :to="{ name: 'Post', params: { id: item['id'] } }" class="edit-post toggle-show-post" @click.prevent="onMoreShow(item['id'])">Смотреть</router-link>
                    </template>
                  </div>
                </div>
                <div class="card-img-top card-img-hover" @click.prevent="onMoreShowHide(item['id'])" :class="{ 'card-img-top_show': isMoreShow(item['id']) }">
                  <img :src="getImage(item['image_host'], item['image_name'], null, 500)" class="img-card">
                  <div class="card-info">
                    <TypeCommerce :data="{
                      value: row.getTypeCommerce(item)
                    }" />
                    <PostSaleStatistic :data="{
                      post_id: row.getId(item)
                    }" />
                    <PostPointStatistic :data="{
                      post_id: row.getId(item)
                    }" />
                  </div>
                </div>
              </span>
            </template>

            <div class="card-footer">
              <div class="d-flex align-items-center">
                <span class="avatar me-3" :style="{ backgroundImage: 'url(' + getImage(item['user_avatar_host'], item['user_avatar_name'], 60) + ')' }"></span>
                <div class="author">
                  <div class="author__name">{{ item['username'] }}</div>
                  <div class="channel">
                    <Channel :data="{
                      channel_id: row.getChannelId(item)
                    }" />
                  </div>
                </div>

                <div class="ms-auto text-right" v-if="row.isPublished(item)">
                  <div>Опубликовано:</div>
                  <div class="text-muted" v-html="row.getPublishedAt(item)"></div>
                </div>

                <div class="ms-auto text-right" v-if="row.isPrePublished(item)">
                  <div>Публикуется...</div>
                  <div class="text-muted" v-if="row.getScheduledPublished(item)" v-html="row.getScheduledPublished(item)"></div>
                  <div class="text-muted" v-else v-html="row.getCreatedAt(item)"></div>
                </div>

                <div class="ms-auto text-right" v-if="row.isPreDeleted(item)">
                  <div>Удаляется...</div>
                  <div class="text-muted" v-html="row.getCreatedAt(item)"></div>
                </div>

                <div class="ms-auto text-right" v-if="row.isCanceled(item)">
                  <div>Отменен</div>
                  <div class="text-muted" v-html="row.getCreatedAt(item)"></div>
                </div>

                <div class="ms-auto text-right" v-if="row.isDeleted(item)">
                  <div>Опубликовано:</div>
                  <div class="text-muted" v-html="row.getPublishedAt(item)"></div>
                </div>

                <div class="ms-auto text-right" v-if="isShowBtnPostRemove(item)">
                  <PostRemove :data="{ post_id: row.getId(item) }" @onRemove="onRemovePost" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="loader-posts">
        <div class="spinner-border" v-show="busy"></div>
      </div>
    </div>
  </div>
</template>

<script>
import "./css/style.css"
import Api from "@/modules/post/components/list/services/Api"
import {defineComponent, ref, watch} from "vue"
import Image from "@/services/Image"
import Helper from "@/services/Helper"
import element from "@/modules/post/components/list/services/element"
import DTBackend from "@/services/DTBackend"
import PostPermission from "@/modules/post/services/permissions/Permission"
import PostRemove from "@/modules/post/components/list/components/post_remove/PostRemove"
import PostSaleStatistic from "@/components/statistic/sale/Sale"
import {fill as saleFill} from "@/components/statistic/sale/services/statistic"
import PostPointStatistic from "@/components/statistic/point/Point"
import {fill as pointFill} from "@/components/statistic/point/services/statistic"
import Channel from "@/modules/post/components/list/components/channel/Channel"
import TypeCommerce from "@/modules/post/components/list/components/type_commerce/TypeCommerce"
import PostImage from "@/modules/post/services/PostImage"
import cfg from "@/services/Config"

export default defineComponent({
  name: "List",
  components: {
    TypeCommerce,
    PostRemove,
    PostSaleStatistic,
    PostPointStatistic,
    Channel,
  },
  props: {
    query: {
      type: Object,
      required: true
    },
    clearResult: {
      type: Boolean,
      required: true
    }
  },
  setup(props, {emit}) {
    let busy = ref(false)
    let items = ref([])
    let eofItems = ref(false)
    let countItems = ref(0)
    let actions = ref([])

    let row = {
      getId: (item) => {
        return element.getId(item)
      },

      getCreatedAt: (item) => {
        let result, dt, createdAt = element.getCreatedAt(item)

        dt = new DTBackend(createdAt)
        result = dt.get("%D.%M.%y <span>%H:%I</span>")

        return result
      },

      getRemovedAt: (item) => {
        let result, dt, removedAt = element.getRemovedAt(item)

        if (removedAt === null) {
          return ""
        }

        dt = new DTBackend(removedAt)
        result = dt.get("%D.%M.%y <span>%H:%I</span>")

        return result
      },

      getScheduledPublished: (item) => {
        let result, dt, scheduledPublished = element.getScheduledPublished(item)

        if (scheduledPublished === null) {
          return ""
        }

        dt = new DTBackend(scheduledPublished)
        result = dt.get("%D.%M.%y <span>%H:%I</span>")

        return result
      },

      getScheduledRemoved: (item) => {
        let result, dt, scheduledRemoved = element.getScheduledRemoved(item)

        if (scheduledRemoved === null) {
          return ""
        }

        dt = new DTBackend(scheduledRemoved)
        result = dt.get("%D.%M.%y <span>%H:%I</span>")

        return result
      },

      isViewScheduledRemoved: (item) => {
        let scheduledRemoved = element.getScheduledRemoved(item)
        let scheduledPublished = element.getScheduledPublished(item)

        return scheduledRemoved !== null && scheduledPublished === null
      },

      getPublishedAt: (item) => {
        let result, dt, publishedAt = element.getPublishedAt(item)

        if (publishedAt === null) {
          return ""
        }

        dt = new DTBackend(publishedAt)
        result = dt.get("%D.%M.%y <span>%H:%I</span>")

        return result
      },

      getChannelId: (item) => {
        return element.getChannelId(item)
      },

      isDraft: (item) => {
        return element.status.isDraft(item)
      },

      isDeleted: (item) => {
        return element.status.isDeleted(item)
      },

      isScheduled: (item) => {
        return element.status.isScheduled(item)
      },

      isPublished: (item) => {
        return element.status.isPublished(item)
      },

      isPrePublished: (item) => {
        return element.status.isPrePublished(item)
      },

      isPreDeleted: (item) => {
        return element.status.isPreDeleted(item)
      },

      isCanceled: (item) => {
        return element.status.isCanceled(item)
      },

      hasImage: (item) => {
        return element.hasImage(item)
      },

      isTopBlock: (item) => {
        return element.imagePosition.isTop(item)
      },

      isBottomBlock: (item) => {
        return element.imagePosition.isBottom(item)
      },

      isNoneBlock: (item) => {
        return element.imagePosition.isNone(item)
      },

      getTypeCommerce: (item) => {
        return element.getTypeCommerce(item)
      },

      hasVideo: (item) => {
        return element.getHasVideo(item)
      },

      getStatus: (item) => {
        return element.getStatus(item)
      },
    }

    watch(() => props.query, (query) => {
      busy.value = true

      if (props.clearResult) {
        clearResult()
      }

      load(query)
    })

    function clearResult() {
      eofItems.value = false
      countItems.value = 0
      items.value = []
    }

    function load(query, offset = null) {
      if (offset !== null) {
        query.offset = offset;
      }

      getApi(query).then((resp) => {
        let data = resp.data

        items.value.push(...data.items)

        if (data.items.length === 0) {
          eofItems.value = true
        }

        countItems.value = data.count

        loadFinal(data.items);
      }).finally(() => {
        busy.value = false

        emit("onRender", {
          "oef_items": eofItems.value,
          "count_items": countItems.value
        })
      })
    }

    function getApi(query) {
      let api = null

      if (query.page === "posts" || query.page === "drafts") {
        api = Api.postsChannel(query)
      } else if (query.page === "search") {
        api = Api.posts(query)
      } else if (query.page === "ranking") {
        api = Api.statisticRanking(query)
      } else {
        throw new Error("Страница неопределена")
      }

      return api
    }

    function loadFinal(items) {
      saleFill(items, getStatisticTypeId())
      pointFill(items, getStatisticTypeId())
    }

    function reload() {
      clearResult()
      load(props.query, 0)
    }

    function getImage(host, name, w = null, h = null) {
      return Image.handle(host, name, w, h)
    }

    function getImageStub(hasVideo) {
      return PostImage.default(hasVideo)
    }

    function isNotFound(items) {
      return Helper.isEmptyObject(items)
    }

    function onMoreShow(post_id) {
      actions.value[post_id] = {
        show: true
      }
    }

    function onMoreHide(post_id) {
      actions.value[post_id] = {
        show: false
      }
    }

    function onMoreShowHide(post_id) {
      actions.value[post_id] = {
        show: !this.isMoreShow(post_id)
      }
    }

    function isMoreShow(post_id) {
      return actions.value[post_id]?.show === true
    }

    function getClassBg(item) {
      let status = row.getStatus(item);
      return Helper.getClassBgItems(status)
    }

    function hasBadge(item) {
      return element.status.isDraft(item)
        || element.status.isScheduled(item)
        || element.status.isDeleted(item)
    }

    function onRemovePost(payload) {
      if (payload === true) {
        reload()
      }
    }

    function isShowBtnPostRemove(item) {
      return row.isDraft(item) && PostPermission.isDelete(row.getChannelId(item))
    }

    function getStatisticTypeId() {
      return cfg._("statistic", "types", "post")
    }

    return {
      busy,
      items,
      getImage,
      isNotFound,
      actions,
      onMoreShow,
      onMoreHide,
      isMoreShow,
      getImageStub,
      getClassBg,
      hasBadge,
      onMoreShowHide,
      row,
      PostPermission,
      onRemovePost,
      isShowBtnPostRemove,
      getStatisticTypeId,
    }
  }
})
</script>

<style scoped></style>
