import Channels from "../pages/Channels"
import authMiddleware from "@/plugins/router/middlewares/auth"

export default [{
    path: "/channels",
    name: "Channels",
    component: Channels,
    meta: {
        middleware: authMiddleware,
    }
}]
