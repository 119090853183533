import { useToast } from "vue-toastification"
import Helper from "@/services/Helper"
import Err from "@/services/err/Err"

export default class NotifyBase {
    static success(msg) {
        const toast = useToast()

        toast.success(msg, {
            timeout: 2000
        });
    }

    static error(msg, payload) {
        const toast= useToast()

        let method = payload?.method
        let url = payload?.url

        if (method && url) {
            let key = Helper.md5(url + method)
            Err.rm(key)
        }

        toast.error(msg, {
            timeout: 4000
        });
    }
}
