<template>
  <div ref="createModal" class="modal fade" id="modal-category" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ params.data.title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <template v-if="isNotLoad()">
            <div class="mb-3">
              <div class="row">
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label class="form-label">Название</label>
                    <input type="text" v-model="fields.name" class="form-control" name="example-text-input" placeholder="Название категории" />
                    <span :class="{'is-invalid': v$.name.$invalid && v$.name.$dirty}"></span>
                    <div class="invalid-feedback" v-for="error of v$.name.$errors" :key="error.$uid">
                      {{ error.$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label class="form-label">Описание</label>
                    <textarea v-model="fields.description" class="form-control" name="example-textarea-input" rows="6" placeholder="Краткое описание..."> </textarea>
                    <span :class="{'is-invalid': v$.description.$invalid && v$.description.$dirty}"></span>
                    <div class="invalid-feedback" v-for="error of v$.description.$errors " :key="error.$uid">
                      {{ error.$message }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="row">
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label class="form-label">Активность</label>
                    <label class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" v-model="fields.active">
                    </label>
                    <span :class="{'is-invalid': v$.active.$invalid && v$.active.$dirty}"></span>
                    <div class="invalid-feedback" v-for="error of v$.active.$errors" :key="error.$uid">
                      {{ error.$message }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="d-flex justify-content-center">
              <div class="spinner-border"></div>
            </div>
          </template>
        </div>
        <template v-if="isNotLoad()">
          <div class="modal-footer">
            <button class="btn btn-secondary" data-bs-dismiss="modal">
              Отмена
            </button>
            <button class="btn btn-danger" @click="onDelete" :class="{'disabled': isDisabledDelBtn()}" v-if="isViewDelBtn()">
              <span class="spinner-border spinner-border-sm me-2" v-show="isProcessDel()"></span>
              Удалить
            </button>
            <button class="btn btn-primary ms-auto" @click="send" :class="{'disabled': isPending()}">
              <span class="spinner-border spinner-border-sm me-2" v-show="isPending()"></span>
              {{ params.data.btn }}
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import "./css/style.css"
import {computed, reactive, ref, onMounted, defineComponent} from 'vue'
import {useVuelidate} from "@vuelidate/core"
import {required} from "@/plugins/vuelidator"
//import IconX from "@/modules/shops/icons/IconX"
import createParams from "./services/params/create"
import updateParams from "./services/params/update"
import Helper from "@/services/Helper"
import Api from "@/modules/shops/pages/categories/services/Api"
import Notify from "@/services/Notify"

export default defineComponent({
  name: "Create",
  components: {
    //IconX,
  },
  setup(props, {emit}) {
    const createModal = ref(null);
    const fields = reactive(getFields())

    let load = ref(false)
    let pending = ref(false)
    let bootModal = null;
    let $externalResults = reactive({})
    let params = ref(getParams())
    let deleting = ref(false)

    const rules = computed(() => ({
      name: {
        required
      },
      description: {

      },
      active: {

      },
    }))

    const v$ = useVuelidate(rules, fields, {$externalResults});

    function getParams() {
      return {
        data: {
          title: "",
          btn: "",
          page: "",
        }
      }
    }

    function resetParams() {
      params.value = getParams()
    }

    function isNotLoad() {
      return load.value === false
    }

    function markLoad() {
      return load.value = true
    }

    function markUnload() {
      return load.value = false
    }

    function isPending() {
      return pending.value === true
    }

    function markPending() {
      return pending.value = true
    }

    function markUnpending() {
      return pending.value = false
    }

    function getFields() {
      return {
        id: null,
        name: '',
        description: '',
        active: true,
      }
    }

    function validate(fn) {
      clearValidate()
      return v$.value.$validate().then((resp) => {
        if (resp === false) {
          Notify.error("Ошибка валидации")
          return false
        }
        fn()
      })
    }

    function clearValidate() {
      v$.value.$clearExternalResults()
      v$.value.$reset()
    }

    function showModal(event) {
      const button = event.relatedTarget
      const type = button.getAttribute('data-type')

      if (type === "create") {
        setParams(createParams)
        create(event)
      } else {
        setParams(updateParams)
        update(event)
      }
    }

    function setParams(val) {
      params.value = val
    }

    function create() {
      clearForm()
      markUnload()
    }

    function update(event) {
      const button = event.relatedTarget
      const id = button.getAttribute('data-id')

      Api.item({
        id: id,
      }).then((resp) => {
        let item = resp.data.item
        setFields(item)
      }).catch(() => {})
      .finally(() => {
        markUnload()
      })
    }

    function setFields(item) {
      fields.id = item["id"]
      fields.name = item["title"]
      fields.description = item["desc"]
      fields.active = item["active"] === 1
    }

    function preShowModal() {
      markLoad()
      resetParams()
      clearValidate()
    }

    onMounted(() => {
      bootModal = new bootstrap.Modal(createModal.value);
      createModal.value.addEventListener('shown.bs.modal', showModal);
      createModal.value.addEventListener('show.bs.modal', preShowModal);
    })

    function getPayload(obj) {
      const formData = new FormData()

      Object.entries(obj).forEach(([key, val]) => {
        formData.append(key, val)
      })

      return formData
    }

    function send() {
      validate(() => {
        markPending()

        let obj = {
          title: fields.name,
          desc: fields.description,
          active: fields.active,
        }

        if (fields.id && isPageUpdate()) {
          obj = Helper.mergeObject(obj, {
            id: fields.id,
          })
        }

        let payload = getPayload(obj)
        params.value.action({
          modal: bootModal,
          payload,
        }).then((resp) => {
          clearForm()
          clearValidate()
          Notify.success(resp)
          if (isPageUpdate()) {
            emit("onUpdate")
          } else {
            emit("onCreate")
          }
        }).catch((resp) => {
          Notify.error(resp)
        }).finally(() => {
          markUnpending()
        })
      })
    }

    function clearForm() {
      let items = getFields()
      for (let key of Object.keys(items)) {
        fields[key] = items[key]
      }
    }

    function onDelete() {
      if (confirm("Вы действительно хотите удалить категорию?")) {
        startDeleting()
        Api.delete({id: fields.id}).then(() => {
        }).then(() => {
          Notify.success("Категория удалена")
          emit("onDelete")
          bootModal.hide();
        }).finally(() => {
          stopDeleting()
        })
      }
    }

    function isDisabledDelBtn() {
      return isProcessDel()
    }

    function isProcessDel() {
      return deleting.value === true
    }

    function startDeleting() {
      deleting.value = true
    }

    function stopDeleting() {
      deleting.value = false
    }

    function isPageUpdate() {
      return params.value.data.page === "update"
    }

    function isViewDelBtn() {
      return isPageUpdate()
    }

    return {
      isNotLoad,
      v$,
      fields,
      createModal,
      send,
      isPending,
      params,
      onDelete,
      isDisabledDelBtn,
      isProcessDel,
      isViewDelBtn,
    }
  }
})
</script>
