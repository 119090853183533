<template>
  <router-link class="dropdown-item" :to="{name: 'Ranking'}">
    <span class="nav-link-icon d-md-none d-lg-inline-block">
      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-sort-ascending" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 6l7 0" /><path d="M4 12l7 0" /><path d="M4 18l9 0" /><path d="M15 9l3 -3l3 3" /><path d="M18 6l0 12" /></svg>
    </span>
    <span class="nav-link-title">
      Ранжирование
    </span>
  </router-link>
</template>

<script>
export default {
  name: "Ranking",
  setup() {
    return {}
  },
}
</script>

<style scoped>

</style>