import Helper from "@/services/Helper"

export default class DT {
    constructor(date) {
        if (date instanceof Date) {
            this.date = date
        } else {
            this.date = new Date(date)
        }
    }

    getDay(zero = false) {
        let day = this.date.getDate()
        return day < 10 && zero ? '0' + day : day
    }

    getMonth(zero = false) {
        let month = this.date.getMonth() + 1

        return month < 10 && zero ? '0' + month : month
    }

    getMonthNatural(zero = false) {
        let month = this.date.getMonth()

        return month < 10 && zero ? '0' + month : month
    }

    getFullYear() {
        return this.date.getFullYear()
    }

    getShortYear() {
        return this.date.getFullYear().toString().substr(-2)
    }

    getHour(zero = false) {
        let hour = this.date.getHours()
        return hour < 10 && zero ? '0' + hour : hour
    }

    getMinutes(zero = false) {
        let minute = this.date.getMinutes()
        return minute < 10 && zero ? '0' + minute : minute
    }

    getSeconds(zero = false) {
        let seconds = this.date.getSeconds()
        return seconds < 10 && zero ? '0' + seconds : seconds
    }

    /**
     * %d - Дата без ведущего нуля.
     * %D - Дата с ведущим нулем.
     * %m - Месяц без ведущего нуля.
     * %M - Месяц с ведущим нулем.
     * %Y - Год.
     * %h - Час без ведущего нуля.
     * %H - Час с ведущим нулем.
     * %i - Минуты без ведущего нуля.
     * %I - Минуты с ведущим нулем.
     * %s - Секунды без ведущего нуля.
     * %S - Секунды с ведущим нулем.
     */
    get(format = '%Y.%M.%D:%H:%I:%S') {
        return Helper.strtr(format, {
            '%d': this.getDay(),
            '%D': this.getDay(true),
            '%m': this.getMonth(),
            '%M': this.getMonth(true),
            '%y': this.getShortYear(),
            '%Y': this.getFullYear(),
            '%h': this.getHour(),
            '%H': this.getHour(true),
            '%i': this.getMinutes(),
            '%I': this.getMinutes(true),
            '%s': this.getSeconds(),
            '%S': this.getSeconds(true),
        });
    }

    setMonth(month) {
        this.date.setMonth(month)

        return this
    }

    setHour(hours) {
        this.date.setHours(hours)

        return this
    }

    setMinute(min) {
        this.date.setMinutes(min)

        return this
    }

    setSec(sec) {
        this.date.setSeconds(sec)
        this.date.setMilliseconds(0)

        return this
    }

    startTimeOfDay() {
        this.setHour(0).setMinute(0).setSec(0)

        return this
    }

    endTimeOfDay() {
        this.setHour(23).setMinute(59).setSec(59)

        return this
    }

    getObject() {
        return this.date
    }

    correctTZ() {
        // для админки нужно форматировать время +3ч
        // она работает по MSK
        return this.setMinute(this.getMinutes() + 180)
    }
}
