<template>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
  <div class="filter-comment" id="filter-comment">
    <div class="filter-comment__search">
      <label class="form-label">Поисковый запрос</label>
      <div class="row g-2">
        <div class="col">
          <div class="input-icon">
            <input @keyup.enter="events.onSearch" type="text" v-model="bootData.search" class="form-control" placeholder="Напишите что-нибудь">
            <button class="btn input-icon-addon__button" @click="events.onSearch">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="10" cy="10" r="7" />
                <line x1="21" y1="21" x2="15" y2="15" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="filter-comment__date">
      <label class="form-label">Дата</label>
      <Datepicker auto-apply v-model="bootData.date" @update:modelValue="events.applyDate" range multi-calendars locale="ru" format="dd.MM.yyyy" placeholder="Выбрать дату" :enable-time-picker="false" />
    </div>

    <div class="filter-comment__select users">
      <label class="form-label">Пользователь</label>
      <Multiselect
          v-model="bootData.users"
          :options="variantsData.users"
          label="name"
          track-by="name"
          :searchable="true"
          :createOption="true"
          mode="tags"
          @change="events.onUser"
          placeholder="Не выбрано"
          noOptionsText="Введите значение и нажмите enter"
      />
    </div>

    <div class="filter-comment__select channels">
      <label class="form-label">Канал</label>
      <Multiselect @change="events.onChannels" v-model="bootData.channels" mode="tags" placeholder="Не выбрано" noResultsText="Не найдено" :searchable="true" :options="variantsData.channels">
        <template v-slot:tag="{ option, handleTagRemove, disabled }">
          <div class="multiselect-tag element" :class="{ 'is-disabled': disabled }">
            <div class="user-name">{{ option.name }}</div>
            <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
              <span class="multiselect-tag-remove-icon"></span>
            </span>
          </div>
        </template>
        <template v-slot:option="{ option }">
          <div class="option-user">
            <div class="user-name"> {{ option.name }}</div>
          </div>
        </template>
      </Multiselect>
    </div>
  </div>
  </div>
  </div>
</template>

<script>
import "./css/style.css"
import {defineComponent, onMounted, reactive, ref} from "vue"
import Multiselect from "@/plugins/multiselect"
import Datepicker from "@/plugins/datepicker"
import DT from "@/services/DT"
import { useRoute } from 'vue-router'

export default defineComponent({
  name: "Filter",
  components: {
    Datepicker,
    Multiselect,
  },
  props: {
    payload: {
      type: Object,
      required: true
    },
  },
  setup(props, {emit}) {
    const route = useRoute()

    let filterInit = ref(false)

    let bootData = reactive({
      search: props.payload.search ?? '',
      date: props.payload.date ?? null,
      users: props.payload.users ?? [],
      channels: props.payload.channels ?? [],
    })

    const variantsData = reactive({
      users: [],
      channels: [],
    })

    const events = {
      applyDate: () => {
        onFilter(false)
      },
      onUser: (value) => {
        // тут приходится подменять вручную, а не использовать v-model
        // так как v-model реагирует перед событием и не учитывает текущий выбор
        // и наполняется предыдущим набором данных
        bootData.users = value
        onFilter(false)
      },
      onChannels: (value) => {
        // тут приходится подменять вручную, а не использовать v-model
        // так как v-model реагирует перед событием и не учитывает текущий выбор
        // и наполняется предыдущим набором данных
        bootData.channels = value
        onFilter(false)
      },
      onSearch: () => {
        onFilter(false)
      },
    }

    onMounted(() => {
      channels()
      setFilter().then(() => {
        onFilter(true)
      })
    })

    function setFilter() {
      return new Promise((resolve) => {
        let filter = getFilter()
        if (filter.channelId !== null) {
          bootData.channels.push(filter.channelId)
        }
        resolve()
      })
    }

    function getFilter() {
      let channelId = null

      if (typeof route.query.channel_id === "string") {
        channelId = parseInt(route.query.channel_id, 10)
      }

      return {
        channelId,
      }
    }

    function channels() {
      let variants = [];

      variants.push(
          {
            "id": 4,
            "name": "Aliexpress",
          },
          {
            "id": 5,
            "name": "DailySales",
          },
          {
            "id": 9,
            "name": "Live",
          },
          {
            "id": 3,
            "name": "Молния",
          },
          {
            "id": 8,
            "name": "Нашли дешевле",
          },
          {
            "id": 13,
            "name": "Промопушка",
          },
          {
            "id": 1,
            "name": "Сайт",
          },
          {
            "id": 7,
            "name": "Travel",
          },
          {
            "id": 12,
            "name": "Ultima",
          },
          {
            "id": 11,
            "name": "Haunt",
          },
          {
            "id": 2,
            "name": "Халявщики",
          },
          {
            "id": 10,
            "name": "Эконом",
          },
      )

      channelsMultiSelect(variants)
    }

    function onFilter(force) {
      if (force === true || filterInit.value === true) {
        pushParent()
        filterInit.value = true
      }
    }

    function channelsMultiSelect(items) {
      for (let prop in items) {
        let obj = items[prop]

        variantsData.channels.push({
          value: Number(obj["id"]),
          name: obj["name"],
        })
      }
    }

    function pushParent() {
      emit("onFilter", getResult())
    }

    function getResult() {
      let date = forDateResult(bootData.date)

      return {
        search: bootData.search,
        date: {
          from: date[0],
          to: date[1],
        },
        users: bootData.users,
        channels: bootData.channels,
      }
    }

    function forDateResult(date) {
      if (date === null) {
        return [
          null,
          null,
        ]
      }

      let from = new DT(date[0]).startTimeOfDay()
      let to = new DT(date[1]).endTimeOfDay()

      from.setMonth(from.getMonthNatural())
      to.setMonth(to.getMonthNatural())

      return [
        from.get("%Y-%M-%D"),
        to.get("%Y-%M-%D"),
      ]
    }

    return {
      events,
      bootData,
      variantsData,
    }
  }
})
</script>

<style scoped></style>
